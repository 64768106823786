// Import variables
import { PAYMENT_TYPES } from 'components/helpers/variables';

const { SUBSCRIPTION_BLUEMEDIA, BLUEMEDIA } = PAYMENT_TYPES;

/**
 * Function converts type from url (react router) to proper type used in request body (that is accepted by API).
 * Api only accept 'packet' as type. Application url can have 'main' or 'packets' (needed for proper authorization).
 * Api only accept 'channel' as type. Application url can have 'channels' (needed for proper authorization).
 * Possibly more in future.
 */
export const convertProductType = (productType) => {
	switch (productType) {
		case 'packets':
			return 'packet';
		case 'main':
			return 'packet';
		case 'channels':
			return 'channel';
		case 'articles':
			return 'article';
		case 'events_live':
		case 'event_live': //used in product_visibility_table.js as redux type is single
			return 'live_event';
		case 'passes':
			return 'pass';
		default:
			return productType;
	}
};

// function that checks number of decimal places in number
export const checkDecimalPlaces = (value) => {
	const decimals = value.toString().split('.')[1];
	if (value && Math.floor(value) !== value)
		return decimals ? decimals.length || 0 : 0;
	return 0;
};

// function return initial values for price from
export const getPriceFormInitialValues = ({ type }) => {
	const model = false;
	const isPacketMain = type === 'main';
	const payment_method = isPacketMain ? SUBSCRIPTION_BLUEMEDIA : BLUEMEDIA;

	return { payment_method, model };
};
