// Import translation
import i18n from 'i18next';

// Import helpers
import { checkDecimalPlaces } from '../helpers';

export default ({ range, price, period, period_unit, payment_method }) => {
	const errors = {};

	// ------------------ Range ------------------
	if (!range || !range.length) {
		errors.range = i18n.t('prices:validation.range_required');
	}

	// ------------------ Price ------------------
	if (!price) {
		errors.price = i18n.t('prices:validation.price_required');
	} else if (price <= 0) {
		errors.price = i18n.t('prices:validation.price_length');
	} else if (checkDecimalPlaces(price) >= 3) {
		errors.price = i18n.t('prices:validation.price_step');
	}

	// ------------------ Period ------------------
	if (!period) {
		errors.period = i18n.t('prices:validation.period_required');
	}

	// ------------------ Period unit ------------------
	if (!period_unit) {
		errors.period_unit = i18n.t('prices:validation.periodUnit_required');
	}

	// ------------------ Payment method ------------------
	if (!payment_method) {
		errors.payment_method = i18n.t('prices:validation.payment_method_required');
	}

	return errors;
};
