import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import {
	fetchProductGeoblockGroupsItem,
	dictionaryGeoblockGroups
} from 'store/actions';

// Import translation
import { useTranslation } from 'react-i18next';

// Import utilities
import { submitForm } from 'components/utilities/form';

// Import components
import { FormTemplate } from 'components/common/templates';
import { FormButtons } from 'components/common/buttons';
import Fields from './fields/Fields';

const ProductGeoblockForm = ({
	history,
	fetchProductGeoblockGroupsItem,
	error,
	isLoaded,
	dictionaryGeoblockGroups,
	geoblockGroups,
	initialValues
}) => {
	const { t } = useTranslation();

	const handleOnSubmit = ({ isEdit, itemID }) => async ({ group_ids }) => {
		const groupIds = _.isArray(group_ids)
			? group_ids
			: group_ids
			? [group_ids]
			: [];

		//  Submit the form with field values
		await submitForm({
			history,
			isEdit,
			itemID,
			resources: { group_ids: groupIds },
			api: 'geoblocking/product',
			notificationName: 'product-geoblock',
			apiSlug: 'updateGroups'
		});
	};

	return (
		// Dispatch fetchProductGeoblockGroupsItem actions in in geoblock_groups
		<div style={{ paddingBottom: 120 }}>
			<FormTemplate
				fetchResourceToEdit={fetchProductGeoblockGroupsItem}
				fetchFormResources={[dictionaryGeoblockGroups]}
				error={error}
				isLoaded={isLoaded}
			>
				{({ isEdit, itemID, decorators }) => (
					<Form
						initialValues={initialValues}
						decorators={decorators}
						onSubmit={handleOnSubmit({ isEdit, itemID })}
						render={({ handleSubmit, submitting }) => (
							<form onSubmit={handleSubmit}>
								<Fields
									geoblockGroups={geoblockGroups}
									submitting={submitting}
								/>

								<FormButtons
									isButtonDisabled={submitting || error}
									path="geoblock_groups"
									buttonText={t('common:buttons.submit')}
								/>
							</form>
						)}
					/>
				)}
			</FormTemplate>
		</div>
	);
};

ProductGeoblockForm.propTypes = {
	history: PropTypes.object.isRequired,
	fetchProductGeoblockGroupsItem: PropTypes.func.isRequired,
	error: PropTypes.bool.isRequired,
	isLoaded: PropTypes.bool.isRequired,
	dictionaryGeoblockGroups: PropTypes.func.isRequired,
	geoblockGroups: PropTypes.array.isRequired,
	initialValues: PropTypes.object.isRequired
};

const mapStateToProps = ({
	product_geoblock_groups: { geoblock_groups, error, isLoaded },
	dictionary: { geoblockGroups }
}) => {
	return {
		initialValues: { group_ids: geoblock_groups },
		error,
		isLoaded,
		geoblockGroups
	};
};

export default compose(
	connect(mapStateToProps, {
		fetchProductGeoblockGroupsItem,
		dictionaryGeoblockGroups
	}),
	withRouter
)(ProductGeoblockForm);
