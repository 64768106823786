import i18n from 'i18next';

export const defaultFieldValues = {
	genres: [],
	slides: '',
	promoted: false,
	active: false,
	subscriber_local_limitation: false,
	adult: false
};

export const BASE_FIELDS = () => [
	{
		name: 'external_uid',
		label: i18n.t('channels:form:fields.external_uid'),
		type: 'text',
		required: true
	},
	{
		name: 'external_stb_id',
		label: i18n.t('channels:form:fields.external_stb_id'),
		type: 'text',
		required: false
	},
	{
		name: 'title',
		label: i18n.t('channels:form:fields.title'),
		type: 'text',
		required: true
	},
	{
		name: 'description',
		label: i18n.t('channels:form:fields.description'),
		type: 'textarea',
		required: false
	},
	{
		name: 'genres',
		label: i18n.t('channels:form:fields.genres'),
		type: 'multi-select',
		required: false
	},
	{
		name: 'slides',
		label: i18n.t('channels:form:fields.slides'),
		type: 'text',
		required: false
	},
	{
		name: 'deep_link',
		label: i18n.t('channels:form:fields.deep_link'),
		type: 'text',
		required: false
	},
	{
		name: 'adult',
		label: i18n.t('channels:form:fields.adult'),
		type: 'checkbox',
		required: false
	}
];
