import i18n from 'i18next';

export const defaultFieldValues = {};

export const BOX_FIELDS = [
	{
		name: 'blackout_streaming_primary',
		label: i18n.t('epg:system:fields.blackout_streaming_primary'),
		type: 'checkbox',
		required: false
	},
	{
		name: 'blackout_catchup_primary',
		label: i18n.t('epg:system:fields.blackout_catchup_primary'),
		type: 'checkbox',
		required: false
	},
	{
		name: 'blackout_npvr_primary',
		label: i18n.t('epg:system:fields.blackout_npvr_primary'),
		type: 'checkbox',
		required: false
	}
];

export const SECONDARY_SCREENS_FIELDS = [
	{
		name: 'blackout_streaming_secondary',
		label: i18n.t('epg:system:fields.blackout_streaming_secondary'),
		type: 'checkbox',
		required: false
	},
	{
		name: 'blackout_catchup_secondary',
		label: i18n.t('epg:system:fields.blackout_catchup_secondary'),
		type: 'checkbox',
		required: false
	},
	{
		name: 'blackout_npvr_secondary',
		label: i18n.t('epg:system:fields.blackout_npvr_secondary'),
		type: 'checkbox',
		required: false
	}
];
