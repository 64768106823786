import React from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';

// Import translation
import { withTranslation } from 'react-i18next';

// Import select table
import checkboxHOC from 'react-table/lib/hoc/selectTable';

// Import global helpers
import {
	createTableOptions,
	prepareDefaultSortedData
} from 'components/helpers/table';

// Import table helpers
import {
	toggleCurrentSelection,
	toggleAllItems,
	convertTableData
} from './chackbox_table_helpers/index';

// Import utilities
import { notificationHandler } from 'components/utilities/notifications/index';
import CustomPagination from 'components/utilities/table/pagination/CustomPagination';

// Import component
import ControlButtons from './ControlButtons';

// Define checkbox table
const CheckboxTable = checkboxHOC(ReactTable);

class ReactServerSideTableCheckbox extends React.PureComponent {
	static propTypes = {
		fetchResourcesAction: PropTypes.func.isRequired,
		resources: PropTypes.shape({
			data: PropTypes.array,
			updateCheckedItems: PropTypes.object,
			options: PropTypes.shape({
				pages: PropTypes.number,
				filters: PropTypes.object
			}),
			loading: PropTypes.bool
		}).isRequired,
		notificationUpdateSuccessTxt: PropTypes.string,
		notificationUpdateErrorTxt: PropTypes.string,
		itemId: PropTypes.number,
		defaultSorted: PropTypes.array,
		isReadOnly: PropTypes.bool.isRequired,
		tableType: PropTypes.string
	};

	static defaultProps = {
		selectItemColumns: [],
		defaultSorted: [],
		resources: {
			updateCheckedItems: {
				success: false,
				error: false
			}
		},
		notificationUpdateSuccessTxt: '',
		notificationUpdateErrorTxt: '',
		itemId: null,
		tableType: ''
	};

	state = {
		selection: [],
		selectAll: false,
		type: undefined,
		systems: undefined,
		platform: undefined,
		errors: {},
		defSorted: prepareDefaultSortedData(
			this.props.defaultSorted,
			this.props.resources?.options?.sorted
		)
	};

	componentDidUpdate(prevProps) {
		const { updateCheckedItems } = this.props.resources;

		const {
			resources: { updateCheckedItems: prevPropsUpdateCheckedItems }
		} = prevProps;

		// Compare prevProps success to current prop success
		if (prevPropsUpdateCheckedItems.success !== updateCheckedItems.success) {
			this.fetchTableDataHandler();
		}
		// Compare prevProps error to current prop error
		if (prevPropsUpdateCheckedItems.error !== updateCheckedItems.error) {
			this.updateCheckedItemsHandler();
		}
	}

	fetchTableDataHandler = () => {
		// Fetch table resources
		this.refReactTable.wrappedInstance.fireFetchData();

		// Add success notification
		notificationHandler(
			'Updated',
			this.props.notificationUpdateSuccessTxt,
			'success'
		);

		// Clear selection
		this.clearAllSelection();
	};

	updateCheckedItemsHandler = () =>
		// Add error notification
		notificationHandler(
			'Error',
			this.props.notificationUpdateErrorTxt,
			'error'
		);

	// Fetch table data
	handleOnFetchData = async (state) => {
		const {
			fetchResourcesAction,
			itemId,
			resourcesForFetchAction,
			tableType,
			searchColumn,
			resources: {
				options: { sorted: reduxSorted }
			}
		} = this.props;

		// Create options
		const options = createTableOptions(
			state,
			reduxSorted,
			tableType,
			searchColumn
		);

		// Fetch resources
		fetchResourcesAction(options, itemId, resourcesForFetchAction);

		// Update local state with selectAll value set to false
		this.setState({ selectAll: false });
	};

	// Toggle selection
	toggleSelection = (key, shift, row) => {
		let { selection, selectAll } = this.state;
		// Get new selected items, and selectAll value
		const { newSelection, newSelectAll } = toggleCurrentSelection({
			row,
			selection: [...selection],
			selectAll
		});
		// Update state data
		this.setState({ selectAll: newSelectAll, selection: newSelection });
	};

	// Toggle all selection
	toggleAll = () => {
		const { selectAll, selection } = this.state;
		// Get wrapped table instance
		const wrappedInstance = this.refReactTable.getWrappedInstance();
		// Get all the records
		const currentRecords = wrappedInstance.getResolvedState().sortedData;
		// Get new selected items, and selectAll value
		const { newSelectAll, newSelection } = toggleAllItems({
			selection,
			selectAll,
			isSelectedHandler: this.isSelected,
			currentRecords
		});
		// Update state with data
		this.setState({ selectAll: newSelectAll, selection: newSelection }, () => {
			if (!this.state.selection.length) {
				this.setState({
					type: undefined,
					systems: undefined,
					platform: undefined,
					errors: { type: null, systems: null, platform: null }
				});
			}
		});
	};

	// Check if current item is elected in the table
	isSelected = (uuid) =>
		this.state.selection.find((item) => item.uuid === uuid) ? true : false;

	// Select type
	selectType = (value) => {
		this.setState({ type: value, errors: { type: null } });
	};

	// Select systems
	selectSystems = (value) => {
		this.setState({ systems: value, errors: { systems: null } });
	};

	// Select platform
	selectPlatform = (value) => {
		this.setState({ platform: value, errors: { platform: null } });
	};

	// Clear all selection from the table
	clearAllSelection = () =>
		this.setState({
			selectAll: false,
			selection: [],
			type: undefined,
			systems: undefined,
			platform: undefined,
			errors: { type: null, systems: null, platform: null }
		});

	submit = (value) => {
		const { updateCheckedItems } = this.props;
		const { selection, type, systems, platform } = this.state;

		// Create data object
		const data = convertTableData({ selection, value, systems, platform });

		const isAllValuesSelected = type && systems && platform;

		// Fetch resources
		// Dispatch an action to update checkItems
		if (isAllValuesSelected) {
			updateCheckedItems(data, type);
		} else {
			const selectFields = ['type', 'systems', 'platform'];
			const errors = selectFields.reduce((acc, next) => {
				if (!this.state[next]) {
					acc[next] = 'is-invalid';
				}
				return acc;
			}, {});

			this.setState({ errors });
		}
	};

	// Send object with id
	handleTurnOn = () => {
		this.submit(true);
	};

	// Send object with id
	handleTurnOff = () => {
		this.submit(false);
	};

	handleGetTrProp = (state, rowInfo, column) => {
		if (
			rowInfo !== undefined &&
			rowInfo.row._original[`${this.props.keyType}`]
		) {
			return {
				className: '-blacklist'
			};
		} else {
			return {};
		}
	};
	render() {
		const { type, errors, systems, platform } = this.state;
		const {
			clearButtonTxt,
			columns,
			t,
			resources: {
				data,
				options: { pages, filters, page, total_results: totalResults },
				loading
			}
		} = this.props;

		// Define page size
		const pageSize = data.length > 0 ? null : 4;

		const checkboxProps = {
			selectAll: this.state.selectAll,
			isSelected: this.isSelected,
			toggleSelection: this.toggleSelection,
			toggleAll: this.toggleAll,
			keyField: this.props.keyField,
			keyStyle: this.props.keyType,
			selectType: 'checkbox'
		};

		const selectionLength = this.state.selection.length;

		return (
			<div className="table-select">
				{!this.props.isReadOnly && (
					<ControlButtons
						handleTurnOn={this.handleTurnOn}
						handleTurnOff={this.handleTurnOff}
						clearAllSelection={this.clearAllSelection}
						selectType={this.selectType}
						selectionLength={selectionLength}
						actionButtonOnTxt={t('epg:control_buttons.on')}
						actionButtonOffTxt={t('epg:control_buttons.off')}
						clearButtonTxt={clearButtonTxt}
						selectTypeTxt={t('epg:control_buttons.type_placeholder')}
						type={type}
						errors={errors}
						selectSystems={this.selectSystems}
						selectPlatform={this.selectPlatform}
						selectSystemsTxt={t('epg:control_buttons.system_placeholder')}
						selectPlatformTxt={t('epg:control_buttons.platform_placeholder')}
						systems={systems}
						platform={platform}
					/>
				)}

				<CheckboxTable
					PaginationComponent={(props) => (
						<CustomPagination tableProps={props} totalResults={totalResults} />
					)}
					ref={(refReactTable) => (this.refReactTable = refReactTable)}
					manual
					data={data}
					columns={[...columns]}
					pages={pages}
					page={page}
					filtered={filters}
					showPageSizeOptions={false}
					pageSize={pageSize}
					loading={loading}
					defaultSorted={this.state.defSorted}
					noDataText={t('common:no_data')}
					previousText={t('common:table_buttons.prev')}
					nextText={t('common:table_buttons.next')}
					className="-striped -select"
					onFetchData={this.handleOnFetchData}
					{...checkboxProps}
					getTrProps={this.handleGetTrProp}
					showPagination={!!pages}
					showPaginationTop={!!pages}
				/>
			</div>
		);
	}
}

export default withTranslation()(ReactServerSideTableCheckbox);
