import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

// Import translation
import { useTranslation } from 'react-i18next';

// Import convert function
import { convertPriceToCents } from 'components/helpers/convert';

// Import helpers
import { convertProductType, getPriceFormInitialValues } from '../helpers';

// Import utilities
import { submitForm } from 'components/utilities/form';

// Validate
import validate from './validation';

// Import components
import { FormTemplate } from 'components/common/templates';
import { FormButtons } from 'components/common/buttons';
import Fields from './fields/Fields';

// Import hooks
import usePriceDefaultValues from './usePriceDefaultValues';

const PriceFormTemplate = ({
	history,
	match: {
		params: { type, id: itemID }
	},
	prevPath
}) => {
	const { t } = useTranslation();

	const productType = convertProductType(type);
	const dateFormat = 'YYYY-MM-DD HH:mm:ss';

	// prepare initial values for form
	const { initialDates, isLoading } = usePriceDefaultValues({
		itemID,
		productType
	});
	const initialValues = getPriceFormInitialValues({ type });
	if (initialDates) initialValues.range = initialDates;

	// Remove "/panel/" from prevPath;
	const redirectPath = prevPath.slice(7);

	const handleOnSubmit = ({ isEdit, itemID }) => async (values) => {
		const {
			price,
			period,
			period_unit,
			payment_method,
			range,
			model,
			views_limit
		} = values;
		const [since, till] = range;

		const sinceFormated = moment(since).format(dateFormat);
		const tillFormated = moment(till).format(dateFormat);

		const resources = {
			since: sinceFormated,
			till: tillFormated,
			price: convertPriceToCents(price),
			period: parseInt(period, 10),
			payment_method,
			product_uuid: itemID,
			product_type: productType,
			period_unit,
			since_first_access: model,
			views_limit
		};

		// Submit the form with field values
		return await submitForm({
			history,
			isEdit,
			itemID,
			resources,
			api: 'timetables/prices',
			redirectPath,
			redirectToPath: true
		});
	};

	return (
		<FormTemplate isLoaded={!isLoading}>
			{({ isEdit, itemID, decorators }) => (
				<Form
					initialValues={initialValues}
					validate={validate}
					decorators={decorators}
					onSubmit={handleOnSubmit({ isEdit, itemID })}
					render={({ handleSubmit, submitting }) => (
						<form onSubmit={handleSubmit}>
							<Fields submitting={submitting} />

							<FormButtons
								isButtonDisabled={submitting}
								path={redirectPath}
								buttonText={t('common:buttons.submit')}
							/>
						</form>
					)}
				/>
			)}
		</FormTemplate>
	);
};

PriceFormTemplate.propTypes = {
	history: PropTypes.object.isRequired,
	match: PropTypes.shape({
		params: PropTypes.shape({ type: PropTypes.string.isRequired })
	}),
	prevPath: PropTypes.string.isRequired
};

const mapStateToProps = ({ previous_location: { pathname } }) => ({
	prevPath: pathname
});

export default compose(connect(mapStateToProps), withRouter)(PriceFormTemplate);
