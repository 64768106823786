import i18n from 'i18next';

import { getCharactersNumberWithoutSpace } from 'components/helpers/characters_counter';

export const defaultFieldValues = {
	active: false,
	products_group_uuid: null,
	subtype: 'vod',
	active_tvonline: true,
	free: false,
	is_audio: 0,
	tags: [],
	type: 'vod',
	genres: [],
	metadata: {
		title: '',
		title_org: '',
		title_brief: '',
		title_brief_org: '',
		summary_short: '',
		summary_medium: '',
		summary_long: '',
		actors: [],
		writers: [],
		directors: [],
		producers: [],
		actors_display: '',
		writers_display: '',
		directors_display: '',
		producers_display: '',
		rating: '0',
		country_of_origin: '',
		channel_name: '',
		distributor_name: '',
		hide_title: false,
		adult: false,
		category: '',
		sub_category: [],
		additional_data: {
			agreement_inconsistent_with_the_regulations: false,
			agreement_offensive: false,
			agreement_pornographic: false,
			agreement_violence: false,
			agreement_hate: false,
			agreement_moral_standards: false,
			agreement_CKF_Warsaw: false,
			show_statute: false,
			inappropriate_content: {
				violence: false,
				sex: false,
				vulgarisms: false,
				drugs: false,
				lack: false
			},
			promoted_product: ''
		},
		additional_metadata: {
			sound: '',
			subtitles: '',
			dramaturgy: [],
			libretto: [],
			choreography: [],
			lead: [],
			curatorial_care: [],
			conducting: [],
			composition: [],
			authors: []
		}
	}
};

export const BASE_FIELDS = ({
	isEdit,
	checkedPermissions,
	changeFieldValue,
	currentValues
}) => [
	{
		name: 'active',
		label: i18n.t('vod:form:fields.active'),
		type: 'checkbox',
		disabled: !checkedPermissions.canActivateProduct,
		fieldInfo: i18n.t('vod:form:fields_info.active')
	},
	{
		name: 'products_group_uuid',
		label: i18n.t('vod:form:fields.products_group_uuid'),
		type: 'select',
		requiredField: true,
		disabled: !isEdit || !checkedPermissions.canActivate
	},
	{
		name: 'subtype',
		label: i18n.t('vod:form:fields.type'),
		type: 'select',
		requiredField: true,
		disabled: false
	},
	{
		name: 'is_audio',
		label: i18n.t('vod:form:fields.is_audio'),
		type: 'select',
		disabled: true
	},
	{
		name: 'metadata.title',
		label: i18n.t('vod:form:fields.title'),
		type: 'text',
		requiredField: true
	},
	{
		name: 'metadata.title_org',
		label: i18n.t('vod:form:fields.title_org'),
		type: 'text'
	},
	{
		name: 'metadata.summary_short',
		label: i18n.t('vod:form:fields.summary_short'),
		type: 'textarea',
		requiredField: true,
		fieldInfo: i18n.t('metric:form:fields_info.summary_short'),
		warningInfo:
			getCharactersNumberWithoutSpace(currentValues?.metadata?.summary_short) >
			300
	},
	{
		name: 'metadata.summary_long',
		label: i18n.t('vod:form:fields.summary_long'),
		type: 'editor',
		fieldInfo: i18n.t('metric:form:fields_info.summary_long'),
		disabledAddingImage: true,
		disabledAddingMedia: true,
		warningInfo:
			getCharactersNumberWithoutSpace(currentValues?.metadata?.summary_long) >
			1000
	},
	{
		name: 'metadata.sub_title',
		label: i18n.t('vod:form:fields.sub_title'),
		type: 'text'
	},
	{
		name: 'metadata.sub_summary_short',
		label: i18n.t('vod:form:fields.sub_summary_short'),
		type: 'textarea'
	},
	{
		name: 'metadata.sub_summary_long',
		label: i18n.t('vod:form:fields.sub_summary_long'),
		type: 'editor',
		disabledAddingImage: true,
		disabledAddingMedia: true
	},
	{
		name: 'metadata.duration',
		label: i18n.t('vod:form:fields.duration'),
		type: 'time',
		fieldInfo: i18n.t('vod:form:fields_info.duration')
	},
	{
		name: 'metadata.year',
		label: i18n.t('vod:form:fields.year'),
		type: 'number'
	},
	{
		name: 'metadata.release_description',
		label: i18n.t('vod:form:fields.release_description'),
		type: 'text',
		fieldInfo: i18n.t('vod:form:fields_info.release_description')
	},
	{
		name: 'metadata.release_year',
		label: i18n.t('vod:form:fields.release_year'),
		type: 'number'
	},
	{
		name: 'metadata.rating',
		label: i18n.t('vod:form:fields.rating'),
		type: 'multi-select',
		mode: null,
		fieldInfo: i18n.t('vod:form:fields_info.rating')
	},
	{
		name: 'metadata.country_of_origin',
		label: i18n.t('vod:form:fields.country_of_origin'),
		type: 'text'
	},
	{
		name: 'metadata.additional_metadata.sound',
		label: i18n.t('common:form:fields.additional_metadata.sound'),
		type: 'text'
	},
	{
		name: 'metadata.additional_metadata.subtitles',
		label: i18n.t('common:form:fields.additional_metadata.subtitles'),
		type: 'text'
	},
	{
		name: 'metadata.actors',
		label: i18n.t('vod:form:fields.actors'),
		type: 'multi-select',
		mode: 'tags',
		fieldInfo: i18n.t('common:form:fields_info.people_list'),
		showSearch: false
	},
	{
		name: 'metadata.writers',
		label: i18n.t('vod:form:fields.writers'),
		type: 'multi-select',
		mode: 'tags',
		fieldInfo: i18n.t('common:form:fields_info.people_list')
	},
	{
		name: 'metadata.directors',
		label: i18n.t('vod:form:fields.directors'),
		type: 'multi-select',
		mode: 'tags',
		fieldInfo: i18n.t('common:form:fields_info.people_list')
	},
	{
		name: 'metadata.producers',
		label: i18n.t('vod:form:fields.producers'),
		type: 'multi-select',
		mode: 'tags',
		fieldInfo: i18n.t('common:form:fields_info.people_list')
	},
	{
		name: 'metadata.additional_metadata.dramaturgy',
		label: i18n.t('common:form:fields.additional_metadata.dramaturgy'),
		type: 'multi-select',
		mode: 'tags',
		dropdownStyle: { display: 'none' },
		fieldInfo: i18n.t('common:form:fields_info.people_list')
	},
	{
		name: 'metadata.additional_metadata.libretto',
		label: i18n.t('common:form:fields.additional_metadata.libretto'),
		type: 'multi-select',
		mode: 'tags',
		dropdownStyle: { display: 'none' },
		fieldInfo: i18n.t('common:form:fields_info.people_list')
	},
	{
		name: 'metadata.additional_metadata.choreography',
		label: i18n.t('common:form:fields.additional_metadata.choreography'),
		type: 'multi-select',
		mode: 'tags',
		dropdownStyle: { display: 'none' },
		fieldInfo: i18n.t('common:form:fields_info.people_list')
	},
	{
		name: 'metadata.additional_metadata.lead',
		label: i18n.t('common:form:fields.additional_metadata.lead'),
		type: 'multi-select',
		mode: 'tags',
		dropdownStyle: { display: 'none' },
		fieldInfo: i18n.t('common:form:fields_info.people_list')
	},
	{
		name: 'metadata.additional_metadata.curatorial_care',
		label: i18n.t('common:form:fields.additional_metadata.curatorial_care'),
		type: 'multi-select',
		mode: 'tags',
		dropdownStyle: { display: 'none' },
		fieldInfo: i18n.t('common:form:fields_info.people_list')
	},
	{
		name: 'metadata.additional_metadata.authors',
		label: i18n.t('common:form:fields.additional_metadata.authors'),
		type: 'multi-select',
		mode: 'tags',
		dropdownStyle: { display: 'none' },
		fieldInfo: i18n.t('common:form:fields_info.people_list')
	},
	{
		name: 'metadata.additional_metadata.conducting',
		label: i18n.t('common:form:fields.additional_metadata.conducting'),
		type: 'multi-select',
		mode: 'tags',
		dropdownStyle: { display: 'none' },
		fieldInfo: i18n.t('common:form:fields_info.people_list')
	},
	{
		name: 'metadata.additional_metadata.composition',
		label: i18n.t('common:form:fields.additional_metadata.composition'),
		type: 'multi-select',
		mode: 'tags',
		dropdownStyle: { display: 'none' },
		fieldInfo: i18n.t('common:form:fields_info.people_list')
	},
	{
		name: 'metadata.category',
		label: i18n.t('vod:form:fields.category'),
		type: 'multi-select',
		mode: null,
		onChangeCallback: () => changeFieldValue('metadata.sub_category', []),
		requiredField: true
	},
	{
		name: 'metadata.sub_category',
		label: i18n.t('vod:form:fields.sub_category'),
		type: 'multi-select',
		mode: 'multiple',
		disabled: !currentValues?.metadata?.category,
		fieldInfo: i18n.t('common:form:fields_info.sub_category'),
		requiredField: true
	},
	{
		name: 'tags',
		label: i18n.t('vod:form:fields.tags'),
		type: 'multi-select',
		mode: 'multiple'
	},
	{
		name: 'genres',
		label: i18n.t('vod:form:fields.genres'),
		type: 'multi-select',
		mode: 'multiple'
	},
	{
		name: 'payment_models',
		label: i18n.t('vod:form:fields.payment_models'),
		type: 'multi-select',
		mode: 'multiple',
		disabled: true
	},
	{
		name: 'metadata.hide_title',
		label: i18n.t('vod:form:fields.hide_title'),
		type: 'checkbox'
	},
	{
		name: 'free',
		label: i18n.t('vod:form:fields.free'),
		type: 'checkbox'
	},
	{
		name: 'metadata.additional_data.show_statute',
		label: i18n.t('common:form:fields.additional_data.show_statute'),
		type: 'checkbox'
	}
];
