import UAParser from 'ua-parser-js';

// Import variables
import { TRANSCODING_STATUS_TYPES } from './variables';

export const getStatusColor = (status) => {
	switch (status) {
		case TRANSCODING_STATUS_TYPES.FAILED:
		case TRANSCODING_STATUS_TYPES.REMOVED:
			return 'red';

		case TRANSCODING_STATUS_TYPES.SUCCEEDED:
			return 'green';

		default:
			return 'geekblue';
	}
};

export const checkIsBrowserSafari = () => {
	const parser = new UAParser();
	const uastring = window.navigator.userAgent;

	const matchiOS = /iphone|ipad|ipod/;

	const userAgent = uastring.toLowerCase();
	const ios = matchiOS.test(userAgent);

	const { browser } = parser.setUA(uastring).getResult();
	const isSafari = browser.name.toLowerCase() === 'safari' || ios;

	return isSafari;
};
