import i18n from 'i18next';

// Import helpers
import { filterOption } from 'components/helpers/fields';

export const BASE_FIELDS = [
	{
		name: 'group_ids',
		label: i18n.t('geoblock_groups:fields.group_ids'),
		type: 'custom-names',
		required: true,
		mode: null,
		showSearch: true,
		labelColumn: 2,
		inputColumn: 10,
		optionFilterProp: 'children',
		filterOption,
		allowClear: true
	}
];
