import i18n from 'i18next';

// Import select options
import { select_payment, select_period } from './select_field_values';

const SUBSCRIPTION_PREFIX = 'subscription_';

export const filterOptions = ({ isPacketMain }) => {
	let paymentOptions = [];

	if (!isPacketMain) {
		paymentOptions = select_payment.filter(
			({ value }) => !value.startsWith(SUBSCRIPTION_PREFIX)
		);

		const periodOptions = select_period.filter(({ value }) => value !== '-1');

		return { paymentOptions, periodOptions };
	}

	paymentOptions = select_payment.filter(({ value }) =>
		value.startsWith(SUBSCRIPTION_PREFIX)
	);

	return { paymentOptions, periodOptions: select_period };
};

export const addPeriodUnitsTranslation = (periodUnits) => {
	const periodUnitsTranslated = periodUnits.map((period) => ({
		...period,
		name: i18n.t(`prices:periods.${period.name}`)
	}));

	return periodUnitsTranslated;
};
