import React from 'react';
import PropTypes from 'prop-types';

// Import utilites
import { renderFields } from 'components/utilities/form/renderFields';

// Import fields
import { BASE_FIELDS } from './input_fields';

const Fields = ({ submitting, casts, platforms, checkedPermissions }) => {
	const selectResources = {
		casts,
		platforms
	};

	return (
		<React.Fragment>
			{BASE_FIELDS(checkedPermissions).map((item, index) =>
				renderFields({ item, index, submitting, selectResources })
			)}
		</React.Fragment>
	);
};

Fields.propTypes = {
	submitting: PropTypes.bool.isRequired,
	casts: PropTypes.array.isRequired,
	platforms: PropTypes.array.isRequired,
	checkedPermissions: PropTypes.object.isRequired
};

export default Fields;
