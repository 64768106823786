export default ({ rank }) => {
	const errors = {};

	// ------------------ Rank ------------------
	if (!rank) {
		errors.rank = 'Pozycja jest wymagana';
	}

	return errors;
};
