import i18n from 'i18next';

// Import helpers
import { FIELDS_NAMES } from '../helpers';
import { filterOption } from 'components/helpers/fields';

const {
	NAME,
	ORDER,
	LINK,
	ELEMENT_TYPE,
	PLATFORM_GROUP,
	ACTIVE
} = FIELDS_NAMES;

export const BASE_FIELDS = ({
	isLinkFieldDisabled,
	isSubmenu,
	isPageFieldDisabled,
	onElementTypeChange
}) => [
	{
		name: NAME,
		label: i18n.t('menu:fields.name'),
		type: 'text',
		requiredField: true
	},
	{
		name: PLATFORM_GROUP,
		label: i18n.t('menu:fields.platform_group'),
		type: 'select',
		requiredField: true,
		isVisible: !isSubmenu
	},
	{
		name: ORDER,
		label: i18n.t('menu:fields.order'),
		type: 'number',
		requiredField: true
	},
	{
		name: ACTIVE,
		label: i18n.t('menu:fields.active'),
		type: 'checkbox'
	},
	{
		name: ELEMENT_TYPE,
		label: i18n.t('menu:fields.element_type'),
		type: 'custom-names',
		mode: null,
		allowClear: true,
		onChangeCallback: onElementTypeChange
	},
	{
		name: LINK,
		label: i18n.t('menu:fields.link'),
		type: 'text',
		disabled: isLinkFieldDisabled,
		requiredField: !isLinkFieldDisabled
	},
	{
		name: 'model_id',
		label: i18n.t(`menu:fields.${isSubmenu ? 'section_or_genre' : 'page'}`),
		type: 'custom-names',
		required: !isPageFieldDisabled,
		mode: null,
		showSearch: true,
		allowClear: true,
		disabled: isPageFieldDisabled,
		requiredField: !isPageFieldDisabled,
		filterOption
	}
];
