import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchChannelsPlatforms } from 'store/actions';

// Import translation
import { useTranslation } from 'react-i18next';

// Import utilities
import { submitForm } from 'components/utilities/form';
import PlatformTable from 'components/utilities/table/platform_table/PlatformTable';

// Import helpers
import { tableRows } from './helpers';

// Validate
import validate from './validation';

// Import components
import { FormTemplate } from 'components/common/templates';
import { FormButtons } from 'components/common/buttons';
// import Fields from './fields/Fields';

const ChannelsPlatformsForm = ({
	history,
	fetchChannelsPlatforms,
	error,
	isLoaded,
	initialValues,
	systemName
}) => {
	const { t } = useTranslation();

	const handleOnSubmit = ({ isEdit, itemID }) => async (values) =>
		// Submit the form with field values
		await submitForm({
			history,
			isEdit,
			itemID,
			resources: { platform_fields: { ...values } },
			api: 'channel/platformFields',
			redirect: false,
			notificationName: 'platform'
		});

	return (
		<FormTemplate
			fetchResourceToEdit={fetchChannelsPlatforms}
			error={error}
			isLoaded={isLoaded}
			fetchActionResources={{ systemName }}
			iboxContentTitle={`${t('channels:platforms.config_title')}`}
		>
			{({ isEdit, itemID, decorators }) => (
				<Form
					initialValues={initialValues}
					onSubmit={handleOnSubmit({ isEdit, itemID, decorators })}
					validate={validate}
					render={({ handleSubmit, submitting, values }) => (
						<form onSubmit={handleSubmit}>
							<PlatformTable
								tableData={values}
								tableRows={tableRows}
								submitting={submitting}
							/>

							<FormButtons
								isButtonDisabled={submitting || error}
								path="channels"
								buttonText={t('common:buttons.submit')}
							/>
						</form>
					)}
				/>
			)}
		</FormTemplate>
	);
};

ChannelsPlatformsForm.propTypes = {
	history: PropTypes.object.isRequired,
	initialValues: PropTypes.object.isRequired,
	fetchChannelsPlatforms: PropTypes.func.isRequired,
	error: PropTypes.bool.isRequired,
	isLoaded: PropTypes.bool.isRequired
};

const mapStateToProps = ({ channels: { platforms } }) => {
	return {
		initialValues: platforms.edit,
		error: platforms.error,
		isLoaded: platforms.isLoaded
	};
};

export default compose(
	connect(mapStateToProps, { fetchChannelsPlatforms }),
	withRouter
)(ChannelsPlatformsForm);
