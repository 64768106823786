import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchProgramSystem } from 'store/actions';

// Import translation
import { useTranslation } from 'react-i18next';

// Import utilities
import { submitForm } from 'components/utilities/form';

// Import variables
import { SYSTEM_CUSTOM_NAMES } from 'components/helpers/variables';

// Import components
import { FormTemplate } from 'components/common/templates';
import { FormButtons } from 'components/common/buttons';
import Fields from './fields/Fields';

const ProgramsSystemsForm = ({
	history,
	fetchProgramSystem,
	error,
	isLoaded,
	initialValues,
	systemName,
	match: {
		params: { channelId, id }
	}
}) => {
	const { t } = useTranslation();

	const redirectPath = `channels/${channelId}/epg/edit/${id}/metadata`;

	const handleOnSubmit = ({ isEdit }) => async (values) =>
		// Submit the form with field values
		await submitForm({
			history,
			isEdit,
			itemID: values.id,
			resources: values,
			api: 'channel/programs/systemFields',
			notificationName: 'configuration'
		});

	return (
		<FormTemplate
			fetchResourceToEdit={fetchProgramSystem}
			error={error}
			isLoaded={isLoaded}
			fetchActionResources={{ systemName }}
			iboxContentTitle={`${t('epg:system.config_title')} ${
				SYSTEM_CUSTOM_NAMES[systemName]
			}`}
		>
			{({ isEdit, itemID, decorators }) => (
				<Form
					initialValues={initialValues}
					onSubmit={handleOnSubmit({ isEdit, itemID })}
					decorators={decorators}
					render={({ handleSubmit, submitting }) => (
						<form onSubmit={handleSubmit}>
							<Fields submitting={submitting} />

							<FormButtons
								isButtonDisabled={submitting || error}
								path={redirectPath}
								buttonText={t('common:buttons.submit')}
							/>
						</form>
					)}
				/>
			)}
		</FormTemplate>
	);
};

ProgramsSystemsForm.propTypes = {
	history: PropTypes.object.isRequired,
	initialValues: PropTypes.object.isRequired,
	fetchProgramSystem: PropTypes.func.isRequired,
	error: PropTypes.bool.isRequired,
	isLoaded: PropTypes.bool.isRequired,
	systemName: PropTypes.string,
	match: PropTypes.shape({
		params: PropTypes.shape({
			channelId: PropTypes.string.isRequired,
			id: PropTypes.string.isRequired
		})
	})
};

const mapStateToProps = ({ epg: { system } }) => {
	return {
		initialValues: system.edit,
		error: system.error,
		isLoaded: system.isLoaded
	};
};

export default compose(
	connect(mapStateToProps, { fetchProgramSystem }),
	withRouter
)(ProgramsSystemsForm);
