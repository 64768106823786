import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// Import redux actions
import { fetchInstitutionsList, fetchPacket } from 'store/actions';

// Import translation
import { useTranslation } from 'react-i18next';

// Import utilities
import { submitForm } from 'components/utilities/form';

// Validate
import validate from './validation';

// Import components
import { FormTemplate } from 'components/common/templates';
import { FormButtons } from 'components/common/buttons';
import Fields from './fields/Fields';

// Import helpers
import { convertPacketExternalIds } from 'components/helpers/convert';
import { addMissingSystems } from 'components/helpers/systems_platforms';
import { convertInstitutionsToValuePairs } from 'store/actions/helpers_actions/convert_helpers';
import { getInstitutionName } from 'components/helpers/institutions_helpers/helpers';

// Import defaultValues
import { defaultFieldValues } from './fields/input_fields';

const PacketsForm = ({
	history,
	fetchPacket,
	fetchInstitutionsList,
	institutionsList,
	error,
	isLoaded,
	initialValues
}) => {
	const { t } = useTranslation();

	const handleOnSubmit = ({ isEdit, itemID }) => async (values) => {
		const external_ids = convertPacketExternalIds(values.external_ids);
		const data = addMissingSystems({ values });
		const resources = { ...data, external_ids };

		// Submit the form with field values
		await submitForm({
			history,
			isEdit,
			itemID,
			resources,
			api: 'packet',
			redirectPath: 'packets'
		});
	};

	const { products_group_uuid } = initialValues;

	// get institution name based on institution id
	const institutionsNames = convertInstitutionsToValuePairs(institutionsList);

	// if we have products_group_uuid find institution on list,
	// otherwise select first institution from list
	const productGroupName = products_group_uuid
		? getInstitutionName(products_group_uuid, institutionsList)
		: institutionsNames[0];

	const formInitialValues = {
		...defaultFieldValues,
		...initialValues,
		products_group_uuid: productGroupName?.value
	};

	return (
		// Dispatch fetchPacket actions in in packets_form
		<FormTemplate
			fetchFormResources={[fetchInstitutionsList]}
			fetchResourceToEdit={fetchPacket}
			error={error}
			isLoaded={isLoaded}
		>
			{({ isEdit, itemID, checkedPermissions, decorators }) => (
				<Form
					initialValues={formInitialValues}
					validate={validate}
					decorators={decorators}
					onSubmit={handleOnSubmit({ isEdit, itemID })}
					render={({ handleSubmit, submitting, values }) => (
						<form onSubmit={handleSubmit}>
							<Fields
								submitting={submitting}
								isEdit={isEdit}
								currentValues={values}
								checkedPermissions={checkedPermissions}
								institutions={institutionsNames}
							/>

							<FormButtons
								isButtonDisabled={submitting || error}
								path="packets"
								buttonText={t('common:buttons.submit')}
							/>
						</form>
					)}
				/>
			)}
		</FormTemplate>
	);
};

PacketsForm.propTypes = {
	history: PropTypes.object.isRequired,
	initialValues: PropTypes.object.isRequired,
	fetchPacket: PropTypes.func.isRequired,
	fetchInstitutionsList: PropTypes.func.isRequired,
	institutionsList: PropTypes.array.isRequired,
	error: PropTypes.bool.isRequired,
	isLoaded: PropTypes.bool.isRequired
};

const mapStateToProps = ({
	packets: { form },
	dictionary: { institutionsList }
}) => {
	return {
		initialValues: form.edit,
		institutionsList,
		error: form.error,
		isLoaded: form.isLoaded
	};
};

export default compose(
	connect(mapStateToProps, { fetchPacket, fetchInstitutionsList }),
	withRouter
)(PacketsForm);
