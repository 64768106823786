import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import services from 'services/services';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';

const useImage = ({
	customId,
	id,
	fetchResourcesAction,
	storePathSelector,
	collectionType,
	isDarkBackground
}) => {
	const { t } = useTranslation();
	// get id from url or set custom id
	const productId = customId ? customId : id;

	const handlefetchResources = () => fetchResourcesAction(productId);

	const handleAssetRemove = async ({ assetId }) => {
		try {
			assetId && (await services.delete(`/assets/${assetId}`));
			message.success(t('common:upload_pictures.remove_success'));
			handlefetchResources();
		} catch (error) {
			message.error(t('common:upload_pictures.remove_error'));
		}
	};

	useEffect(() => {
		handlefetchResources();
		// eslint-disable-next-line
	}, []);

	const { data: files, isLoaded, error } = useSelector(
		(state) => state[storePathSelector].files[collectionType]
	);

	const hasImage = files?.length > 0;

	const conditionalClassName = isDarkBackground ? 'dark-background-logo' : '';

	return {
		productId,
		files,
		isLoaded,
		error,
		hasImage,
		conditionalClassName,
		fetchResources: handlefetchResources,
		assetRemove: handleAssetRemove
	};
};

export default useImage;
