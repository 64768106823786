import React from 'react';
import i18n from 'i18next';

// Import variables
import {
	GLOBAL_PRODUCT_TYPES,
	SYSTEM_TYPES,
	SYSTEM_TYPES_FROM_ENV
} from 'components/helpers/variables';

// Import helpers
import { applySystemTabs } from 'components/helpers/tabs_helpers/tabs_helpers';

// Import utilities
import { generateContentTranslationsTabs } from 'components/utilities/content_translation/tabs/content_translations_tabs';

// import content translation fields
import { CHANNELS_INPUT_FIELDS_DATA } from 'components/utilities/content_translation/input_fields';

// Validate
import { channelsTranslationValidate } from 'components/utilities/content_translation/validation';

// Import components
import ChannelsForm from '../../channels_form/ChannelsForm';
import ChannelsImages from '../../channels_file/ChannelsImages';
import Epg from '../epg/EPG';
import ChannelsMaterials from '../materials/ChannelsMaterials';
import ChannelsAvailability from '../../channels_availability/ChannelsAvailability';
import ProductVisibility from 'components/utilities/product_visibility/table/ProductVisibility';
import ChannelsVOTT from '../../channels_contexts/ChannelsVOTT';
import ChannelsTVO from '../../channels_contexts/ChannelsTVO';
import ChannelsOBOX from '../../channels_contexts/ChannelsOBOX';

const systemChannelTabs = {
	[SYSTEM_TYPES.ott]: {
		name: i18n.t('common:tabs.ott'),
		path: 'ott',
		disableOnCreate: true,
		component: <ChannelsVOTT />,
		className: 'tab--bold'
	},
	[SYSTEM_TYPES.tvonline]: {
		name: i18n.t('common:tabs.tvonline'),
		path: 'tvonline',
		disableOnCreate: true,
		component: <ChannelsTVO />,
		className: 'tab--bold'
	},
	[SYSTEM_TYPES.obox]: {
		name: i18n.t('common:tabs.obox'),
		path: 'obox',
		disableOnCreate: true,
		component: <ChannelsOBOX />,
		className: 'tab--bold'
	}
};
const basicChannelTabs = [
	{
		name: i18n.t('common:tabs.metadata'),
		path: 'metadata',
		disableOnCreate: false,
		component: <ChannelsForm />
	},
	{
		name: i18n.t('common:tabs.epg'),
		path: 'epg',
		disableOnCreate: true,
		component: <Epg />
	},
	{
		name: i18n.t('common:tabs.pictures'),
		path: 'pictures',
		disableOnCreate: true,
		component: <ChannelsImages />
	},
	{
		name: i18n.t('common:tabs.materials'),
		path: 'materials',
		disableOnCreate: true,
		component: <ChannelsMaterials />
	},
	{
		name: i18n.t('common:tabs.availability'),
		path: 'availability',
		disableOnCreate: true,
		component: <ChannelsAvailability />
	},
	{
		name: i18n.t('common:tabs.visibility'),
		path: 'visibility',
		disableOnCreate: true,
		component: <ProductVisibility productType={GLOBAL_PRODUCT_TYPES.CHANNEL} />
	}
];

export const channelsTabs = (contentTranslationLanguages) => {
	return applySystemTabs(SYSTEM_TYPES_FROM_ENV, systemChannelTabs, [
		...basicChannelTabs,
		...generateContentTranslationsTabs(
			CHANNELS_INPUT_FIELDS_DATA,
			contentTranslationLanguages,
			channelsTranslationValidate
		)
	]);
};
