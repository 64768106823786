import React from 'react';
import PropTypes from 'prop-types';

// Import translation
import { useTranslation } from 'react-i18next';

// Import fields
import { BASE_FIELDS } from './input_fields';

// Import utilites
import { renderFields } from 'components/utilities/form/renderFields';

const Fields = ({ submitting, isEdit, checkedPermissions, genres }) => {
	const { t } = useTranslation();

	const selectResources = { genres };

	return (
		<React.Fragment>
			<h3>{t('common:form.base_info')}</h3>
			<div className="hr-line-solid">&nbsp;</div>
			{BASE_FIELDS({
				isEdit,
				checkedPermissions
			}).map((item, index) =>
				renderFields({ item, index, submitting, selectResources })
			)}
		</React.Fragment>
	);
};

Fields.propTypes = {
	submitting: PropTypes.bool.isRequired,
	genres: PropTypes.array
};

export default Fields;
