import i18n from 'i18next';

// channels platforms table rows
export const tableRows = [
	{
		rowName: i18n.t('channels:platforms:row_names.npvr_active'),
		rowValue: 'npvr_active'
	},
	{
		rowName: i18n.t('channels:platforms:row_names.npvr_seek'),
		rowValue: 'npvr_seek'
	},
	{
		rowName: i18n.t('channels:platforms:row_names.start_over_active'),
		rowValue: 'start_over_active'
	},
	{
		rowName: i18n.t('channels:platforms:row_names.start_over_seek'),
		rowValue: 'start_over_seek'
	},
	{
		rowName: i18n.t('channels:platforms:row_names.catch_up_active'),
		rowValue: 'catch_up_active'
	},
	{
		rowName: i18n.t('channels:platforms:row_names.catch_up_seek'),
		rowValue: 'catch_up_seek'
	},
	{
		rowName: i18n.t('channels:platforms:row_names.catch_up_time'),
		rowValue: 'catch_up_time'
	}
];
