import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Field } from 'react-final-form';
import { useParams } from 'react-router-dom';

// Import translation
import { useTranslation } from 'react-i18next';

// Import components
import {
	InputLabel,
	SelectLabel,
	RangePickerLabel
} from 'components/common/inputs';
import { dictionaryPeriodUnit } from 'store/actions';

// Import helpers
import { addPeriodUnitsTranslation, filterOptions } from './helpers';
import { select_model } from './select_field_values';

// Import variables
import { CORE_PATHS } from 'components/helpers/variables';

const { PASSES } = CORE_PATHS;

const Fields = ({ submitting }) => {
	const dispatch = useDispatch();

	const { t } = useTranslation();
	const { type } = useParams();

	const isPacketMain = type === 'main';
	const isModelVisible = type !== 'articles';
	const isViewsLimitFieldVisible = type !== 'articles';

	useEffect(() => {
		dictionaryPeriodUnit()(dispatch);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const periodUnits = useSelector(({ dictionary }) => dictionary.period_unit);

	const periodUnitsWithTranslation = addPeriodUnitsTranslation(periodUnits);

	const { paymentOptions } = filterOptions({ isPacketMain });

	// Translation of limit label
	const limitLabel =
		type === PASSES
			? t('prices:fields.usage_limit')
			: t('prices:fields.views_limit');

	return (
		<React.Fragment>
			<Field
				disabled={submitting}
				name="range"
				type="text"
				placeholder={[
					t('availability:fields.since'),
					t('availability:fields.till')
				]}
				label={t('availability:fields.range')}
				id="range"
				required={true}
				showTime={true}
				rangeFormat="YYYY-MM-DD HH:mm:ss"
				component={RangePickerLabel}
			/>
			<div className="hr-line-dashed">&nbsp;</div>
			<Field
				disabled={submitting}
				name="price"
				type="number"
				placeholder={t('prices:fields.price')}
				label={t('prices:fields.price')}
				id="price"
				required={true}
				component={InputLabel}
				min={0}
				step={0.01}
			/>
			<div className="hr-line-dashed">&nbsp;</div>
			<Field
				disabled={submitting}
				name="period"
				type="number"
				placeholder={t('prices:fields.period')}
				label={t('prices:fields.period')}
				id="period"
				required={true}
				min={0}
				component={InputLabel}
			></Field>
			<div className="hr-line-dashed">&nbsp;</div>
			<Field
				disabled={submitting}
				name="period_unit"
				type="select"
				placeholder={t('prices:fields.periodUnit')}
				label={t('prices:fields.periodUnit')}
				id="period_unit"
				required={true}
				defaultValue={periodUnits[0]?.value}
				component={SelectLabel}
			>
				{periodUnitsWithTranslation?.map(({ name, value }) => (
					<option key={value} value={value}>
						{name}
					</option>
				))}
			</Field>
			{isModelVisible && (
				<>
					<div className="hr-line-dashed">&nbsp;</div>
					<Field
						disabled={submitting || isPacketMain}
						name="model"
						type="select"
						placeholder={t('prices:fields.model')}
						label={t('prices:fields.model')}
						id="model"
						component={SelectLabel}
					>
						{select_model.map(({ name, value }) => (
							<option key={name} value={value}>
								{name.toUpperCase()}
							</option>
						))}
					</Field>
				</>
			)}
			{isViewsLimitFieldVisible && (
				<>
					<div className="hr-line-dashed">&nbsp;</div>
					<Field
						disabled={submitting || isPacketMain}
						name="views_limit"
						type="number"
						placeholder={limitLabel}
						label={limitLabel}
						id="views_limit"
						min={0}
						fieldInfo={t('prices:fields_info.views_limit')}
						component={InputLabel}
					></Field>
				</>
			)}
			<div className="hr-line-dashed">&nbsp;</div>
			<Field
				disabled={submitting}
				name="payment_method"
				type="select"
				placeholder={t('prices:fields.payment_method')}
				label={t('prices:fields.payment_method')}
				id="payment_method"
				required={true}
				defaultValue={paymentOptions[0]}
				component={SelectLabel}
			>
				{paymentOptions.map(({ name, value }) => (
					<option key={value} value={value}>
						{name.toUpperCase()}
					</option>
				))}
			</Field>
			<div className="hr-line-dashed">&nbsp;</div>
		</React.Fragment>
	);
};

Fields.propTypes = {
	submitting: PropTypes.bool.isRequired
};

export default Fields;
