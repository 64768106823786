import React from 'react';

// Import variables
import { SYSTEM_TYPES_FROM_ENV } from 'components/helpers/variables';

// Import translation
import i18n from 'i18next';

// Import utilities
import ActiveIcon from 'components/utilities/table/render_icons/ActiveIcon';

/**
 * Function that returns arrays columns for systems visibility based
 * on systems in env file
 */
export const systemTableColumns = () => {
	if (SYSTEM_TYPES_FROM_ENV && SYSTEM_TYPES_FROM_ENV.length > 0) {
		return SYSTEM_TYPES_FROM_ENV.map((system) => ({
			Header: i18n.t(`common:table_columns.active_${system}`),
			accessor: `active_${system}`,
			width: 75,
			headerClassName: 'text-center',
			className: 'text-center',
			Cell: ({ row: { _original } }) => (
				<ActiveIcon active={_original[`active_${system}`]} />
			)
		}));
	} else return [];
};

/**
 * Function that inserts additional columns into existing table
 * @param {array} basicColumns - basic columns for table
 * @param {*} addedColumns - columns that needs to be inserted
 * @param {*} columnIndex - at which column index we should insert new columns
 */
export const applyTableColumns = (basicColumns, addedColumns, columnIndex) => {
	const appliedColumns = [...basicColumns];
	appliedColumns.splice(columnIndex, 0, ...addedColumns);
	return appliedColumns;
};
