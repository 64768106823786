import i18n from 'i18next';

// Import helpers
import { getCharactersNumberWithoutSpace } from 'components/helpers/characters_counter';

export const defaultFieldValues = {
	type: 'main',
	external_ids: [],
	url: '',
	free_vod: false,
	active: false,
	active_ott: false,
	active_obox: false,
	active_tvonline: true,
	slug: null,
	visible_primary: true,
	visible_secondary: true
};

export const BASE_FIELDS = ({ checkedPermissions, isEdit, currentValues }) => [
	{
		name: 'type',
		label: i18n.t('main:fields.type'),
		type: 'select',
		requiredField: true,
		disabled: true
	},
	{
		name: 'external_ids',
		label: i18n.t('main:fields.external_id'),
		type: 'text',
		requiredField: true
	},
	{
		name: 'title',
		label: i18n.t('main:fields.title'),
		type: 'text',
		requiredField: true
	},
	{
		name: 'description',
		label: i18n.t('main:fields.description'),
		type: 'textarea',
		requiredField: true
	},
	{
		name: 'short_description',
		label: i18n.t('main:fields.short_description'),
		type: 'textarea',
		requiredField: true,
		fieldInfo: i18n.t('main:fields_info.short_description'),
		warningInfo:
			getCharactersNumberWithoutSpace(currentValues?.short_description) > 200
	},
	{
		name: 'order',
		label: i18n.t('main:fields.order'),
		type: 'number',
		requiredField: true
	},
	{
		name: 'url',
		label: i18n.t('main:fields.url'),
		type: 'text',
		requiredField: true
	},
	{
		name: 'active',
		label: i18n.t('main:fields.active'),
		type: 'checkbox',
		disabled: !checkedPermissions.canActivateProduct
	},
	{
		name: 'created_at',
		label: i18n.t('main:fields.created_at'),
		type: 'text',
		disabled: true,
		isVisible: !isEdit
	},
	{
		name: 'updated_at',
		label: i18n.t('main:fields.updated_at'),
		type: 'text',
		disabled: true,
		isVisible: !isEdit
	}
];
