import i18n from 'i18next';

export const BASE_FIELDS = [
	{
		name: 'title',
		label: i18n.t('notifications:form:fields.title'),
		type: 'text',
		requiredField: true
	},
	{
		name: 'message',
		label: i18n.t('notifications:form:fields.message'),
		type: 'textarea',
		requiredField: true
	},
	{
		name: 'platforms',
		label: i18n.t('notifications:form:fields.platforms'),
		type: 'multi-select',
		requiredField: true,
		mode: 'multiple',
		showSearch: true
	},
	{
		name: 'systems',
		label: i18n.t('notifications:form:fields.systems'),
		type: 'custom-names',
		requiredField: true,
		mode: 'multiple',
		showSearch: true
	},
	{
		name: 'file',
		label: i18n.t('notifications:form:fields.file'),
		type: 'file'
	},
	{
		name: 'sn_from',
		label: i18n.t('notifications:form:fields.sn_from'),
		type: 'text'
	},
	{
		name: 'sn_to',
		label: i18n.t('notifications:form:fields.sn_to'),
		type: 'text'
	},
	{
		name: 'sn_list',
		label: i18n.t('notifications:form:fields.sn_list'),
		type: 'textarea'
	},
	{
		name: 'send_to_all',
		label: i18n.t('notifications:form:fields.send_to_all'),
		type: 'checkbox'
	}
];
