import React from 'react';
import { Link } from 'react-router-dom';

// Import translation
import i18n from 'i18next';

// Import helpers
import { getProductType, getProductUrl } from './helpers';

// Import components
import StatusCode from 'components/common/status_code/StatusCode';

export const createColumns = () => [
	{
		Header: i18n.t('activity:table_columns.id'),
		accessor: 'id',
		Cell: ({
			row: {
				_original: { id }
			}
		}) => <Link to={`/panel/activity/${id}`}>{id}</Link>
	},
	{
		Header: i18n.t('activity:table_columns.email'),
		accessor: 'operator_email',
		Cell: ({
			row: {
				_original: { operator_id, operator_email }
			}
		}) => (
			<Link to={`/panel/operators/edit/${operator_id}/metadata`}>
				{operator_email}
			</Link>
		)
	},
	{
		Header: i18n.t('activity:table_columns.model_type'),
		accessor: 'model_type',
		Cell: ({
			row: {
				_original: { model_type }
			}
		}) => <>{getProductType(model_type)}</>
	},
	{
		Header: i18n.t('activity:table_columns.model_id'),
		accessor: 'model_id',
		Cell: ({
			row: {
				_original: { model_id, model_type }
			}
		}) => <Link to={getProductUrl(model_type, model_id)}>{model_id}</Link>
	},
	{
		Header: i18n.t('activity:table_columns.permission'),
		accessor: 'permission'
	},
	{
		Header: i18n.t('activity:table_columns.status_code'),
		accessor: 'status_code',
		Cell: ({
			row: {
				_original: { status_code }
			}
		}) => <StatusCode statusCode={status_code} />
	},
	{
		Header: i18n.t('activity:table_columns.created_at'),
		accessor: 'created_at'
	}
];
