// Import translation
import i18n from 'i18next';

// Import variables
import { PAYMENT_TYPES } from 'components/helpers/variables';

export const select_payment = Object.values(PAYMENT_TYPES).map((payment) => ({
	name: i18n.t(`prices:payments.${payment}`),
	value: payment
}));

export const select_period = [
	{
		name: i18n.t('common:time_of_availability'),
		value: '0'
	},
	{
		name: '24h',
		value: '24'
	},
	{
		name: '48h',
		value: '48'
	},
	{
		name: '72h',
		value: '72'
	},
	{
		name: i18n.t('prices:recurring_payment'),
		value: '-1'
	}
];

export const select_model = [
	{ name: i18n.t('prices:models.since_time_purchase'), value: false },
	{ name: i18n.t('prices:models.since_first_access'), value: true }
];
