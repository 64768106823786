import React from 'react';
import i18n from 'i18next';

// Import variables
import {
	SYSTEM_TYPES,
	SYSTEM_TYPES_FROM_ENV
} from 'components/helpers/variables';

// Import helpers
import { applySystemTabs } from 'components/helpers/tabs_helpers/tabs_helpers';

// Import utilities
import { generateContentTranslationsTabs } from 'components/utilities/content_translation/tabs/content_translations_tabs';

// import content translation fields
import { SECTIONS_INPUT_FIELDS_DATA } from 'components/utilities/content_translation/input_fields';

// Import components
import SectionsForm from '../../sections_form/SectionsForm';
import SectionsProducts from '../../sections_products/SectionsProducts';
import SectionsPreview from '../../sections_preview/SectionsPreview';
import SectionsPages from '../../sections_pages/SectionsPages';

const systemSectionTabs = {
	[SYSTEM_TYPES.ott]: {
		name: i18n.t('common:tabs.ott_preview'),
		path: 'ott_preview',
		disableOnCreate: true,
		component: <SectionsPreview systemName={SYSTEM_TYPES.ott} />
	},
	[SYSTEM_TYPES.tvonline]: {
		name: i18n.t('common:tabs.tvonline_preview'),
		path: 'tvonline_preview',
		disableOnCreate: true,
		component: <SectionsPreview systemName={SYSTEM_TYPES.tvonline} />
	},
	[SYSTEM_TYPES.obox]: {
		name: i18n.t('common:tabs.obox_preview'),
		path: 'obox_preview',
		disableOnCreate: true,
		component: <SectionsPreview systemName={SYSTEM_TYPES.obox} />
	}
};

export const basicSectionsTabs = [
	{
		name: i18n.t('common:tabs.metadata'),
		path: 'metadata',
		disableOnCreate: false,
		component: <SectionsForm />
	},
	{
		name: i18n.t('common:tabs.static_elements'),
		path: 'static-elements',
		disableOnCreate: true,
		component: <SectionsProducts />
	},
	{
		name: i18n.t('common:tabs.on_pages'),
		path: 'pages',
		disableOnCreate: true,
		component: <SectionsPages />
	}
];

export const sectionsTabs = (contentTranslationLanguages) => {
	return applySystemTabs(
		SYSTEM_TYPES_FROM_ENV,
		systemSectionTabs,
		[
			...basicSectionsTabs,
			...generateContentTranslationsTabs(
				SECTIONS_INPUT_FIELDS_DATA,
				contentTranslationLanguages
			)
		],
		2
	);
};
