import i18n from 'i18next';

export const BASE_FIELDS = (checkedPermissions) => [
	{
		name: 'external_uid',
		label: i18n.t('epg:fields.external_uid'),
		type: 'text',
		required: true,
		disabled: true
	},
	{
		name: 'synchronization_turned_off',
		label: i18n.t('epg:fields.synchronization_turned_off'),
		type: 'checkbox',
		required: false
	},
	{
		name: 'content_id',
		label: i18n.t('epg:fields.content_id'),
		type: 'text',
		required: true
	},
	{
		name: 'title',
		label: i18n.t('epg:fields.title'),
		type: 'text',
		required: true
	},
	{
		name: 'sub_title',
		label: i18n.t('epg:fields.sub_title'),
		type: 'text',
		required: false
	},
	{
		name: 'original_title',
		label: i18n.t('epg:fields.original_title'),
		type: 'text',
		required: false
	},
	{
		name: 'star_rating',
		label: i18n.t('epg:fields.star_rating'),
		type: 'number',
		required: false
	},
	{
		name: 'broadcast_date',
		label: i18n.t('epg:fields.broadcast_date'),
		type: 'text',
		required: true
	},
	{
		name: 'date',
		label: i18n.t('epg:fields.date'),
		type: 'text',
		required: false
	},
	{
		name: 'active',
		label: i18n.t('epg:fields.active'),
		type: 'checkbox',
		required: false,
		disabled: !checkedPermissions.canActivateProduct
	},
	{
		name: 'description_short',
		label: i18n.t('epg:fields.description_short'),
		type: 'textarea',
		required: true
	},
	{
		name: 'description_long',
		label: i18n.t('epg:fields.description_long'),
		type: 'textarea',
		required: false
	},
	{
		name: 'country',
		label: i18n.t('epg:fields.country'),
		type: 'text',
		required: false
	},
	{
		name: 'category',
		label: i18n.t('epg:fields.category'),
		type: 'text',
		required: false
	},
	{
		name: 'pc_rating',
		label: i18n.t('epg:fields.pc_rating'),
		type: 'number',
		required: false
	}
];
