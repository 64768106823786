// Import translation
import i18n from 'i18next';

export default ({ external_uid, title }) => {
	const errors = {};

	// ------------------ External_uid ------------------
	if (!external_uid) {
		errors.external_uid = i18n.t('channels:form:validation.external_uid');
	} else if (external_uid < 1) {
		errors.external_uid = i18n.t(
			'channels:form:validation.external_uid_positive'
		);
	}

	// ------------------ Title ------------------
	if (!title) {
		errors.title = i18n.t('channels:form:validation.title');
	}

	return errors;
};
