import React from 'react';

// Import components
import ProgramsSystemForm from './programs_systems_form/ProgramsSystemsForm';

// Import variables
import { SYSTEM_TYPES } from 'components/helpers/variables';

function ProgramTVO() {
	return <ProgramsSystemForm systemName={SYSTEM_TYPES.tvonline} />;
}

export default ProgramTVO;
