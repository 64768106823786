import React from 'react';
import PropTypes from 'prop-types';
import services from 'services/services';
import { Upload, message } from 'antd';

// Import translation
import i18n from 'i18next';

// Import components
import UploadButton from './UploadButton';

export class UploadImage extends React.Component {
	state = {
		uploading: false,
		fileList: []
	};

	handleChange = ({ fileList }) => {
		this.setState({ uploading: true, fileList });
	};

	uploadConfig = (onProgress) => ({
		onUploadProgress: function(progressEvent) {
			const percentCompleted = Math.round(
				(progressEvent.loaded * 100) / progressEvent.total
			);
			onProgress({ percent: percentCompleted });
		}
	});

	handleImageUpload = async ({ file, onSuccess, onError, onProgress }) => {
		try {
			const { modelId, collection, mediaType, successCallback } = this.props;
			// Format uploaded file
			const formData = new FormData();
			// Append uploaded file, and options
			formData.append('file', file);
			formData.append('collection', collection);
			formData.append('model_id', modelId);
			formData.append('model_type', mediaType);

			// Upload image
			const { data } = await services.post(
				'/assets/create',
				formData,
				this.uploadConfig(onProgress)
			);

			// Trigger on success function
			onSuccess(data, data);
			message.success(i18n.t('common:upload_pictures.upload_success'));
			this.setState({ uploading: false, fileList: [] });
			successCallback(data);
		} catch (error) {
			// If there is an error trigger error function
			onError();
			// Check if status is equal to 413 than show message that file is too large
			error.response.status === 413 &&
				message.error(i18n.t('common:upload_pictures.upload_error'));
			error.response.status === 422 &&
				message.error(i18n.t('common:upload_pictures.upload_error'));
		}
	};

	render() {
		const { uploading, fileList } = this.state;

		return (
			<Upload
				listType="picture-card"
				fileList={fileList}
				showUploadList={{ showDownloadIcon: false }}
				onChange={this.handleChange}
				customRequest={this.handleImageUpload}
			>
				{!uploading && <UploadButton />}
			</Upload>
		);
	}
}

UploadImage.defaultProps = {
	modelId: '',
	collection: '',
	mediaType: '',
	successCallback: () => {}
};

UploadImage.propTypes = {
	modelId: PropTypes.string.isRequired,
	collection: PropTypes.string.isRequired,
	mediaType: PropTypes.string.isRequired,
	successCallback: PropTypes.func.isRequired
};

export default UploadImage;
