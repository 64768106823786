import services from 'services/services';
import * as types from '../types';

// Impport translations
import i18n from 'i18next';

// Import utilities
import { notificationHandler } from 'components/utilities/notifications';

// Import helpers
import { checkFetchErrors } from '../helpers_actions/convert_helpers';

// GET UPLOAD URL
export const getUploadUrl = (id, collection, model_type) => async (
	dispatch
) => {
	try {
		dispatch({
			type: types.GET_UPLOAD_MATERIALS_URL_LOADING
		});

		const url = `/assets/uploadUrl?model_type=${model_type}&model_id=${id}&collection=${collection}`;
		const { data } = await services.get(url);

		dispatch({
			type: types.GET_UPLOAD_MATERIALS_URL_SUCCESS,
			payload: data
		});
	} catch (error) {
		dispatch({
			type: types.GET_UPLOAD_MATERIALS_URL_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};

// GET UPLOADED MATERIALS
export const getUploadedProductMaterials = ({
	id,
	modelType,
	locationType
}) => async (dispatch) => {
	try {
		dispatch({
			type: types.GET_UPLOADED_MATERIALS_LOADING
		});

		let url = `/assets/${id}/list?model_type=${modelType}&collections[]=audio&collections[]=video`;

		if (locationType) {
			url += `&${locationType}`;
		}

		const { data } = await services.get(url);

		dispatch({
			type: types.GET_UPLOADED_MATERIALS_SUCCESS,
			payload: data
		});
	} catch (error) {
		dispatch({
			type: types.GET_UPLOADED_MATERIALS_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};

// CLEAR UPLOADED MATERIALS TABLE
export const clearUploadedMaterialsTable = () => (dispatch) => {
	dispatch({
		type: types.CLEAR_UPLOAD_MATERIALS_TABLE
	});
};

// CONFIRM UPLOAD
export const confirmUpload = async (assetId, fileName) => {
	try {
		const url = `/assets/${assetId}/confirmUpload`;
		await services.post(url, { file_name: fileName });
	} catch (error) {
		return checkFetchErrors(error);
	}
};

// DELETE UPLOADED MATERIAL
export const deleteUploadedAsset = async (id, callback) => {
	try {
		const url = `/ingester/asset/${id}`;
		await services.delete(url);
		callback();

		// show success notification
		notificationHandler(
			i18n.t('messages:notifications.deleted'),
			i18n.t('messages:notifications.successfuly_deleted')
		);
	} catch (error) {
		// show error notification
		notificationHandler(
			i18n.t('messages:notifications.error'),
			i18n.t('common:errors.common_error_message'),
			'error'
		);
	}
};

// TRANSCODE MATERIAL
export const transcodeAsset = (assetId, videoType) => async (dispatch) => {
	try {
		const url = '/ingester/encodeFromAssets';
		await services.post(url, { assets_ids: [assetId], video_type: videoType });

		// show success notification
		notificationHandler(
			i18n.t('messages:notifications.commissioned'),
			i18n.t('messages:notifications.asset_transcoding_success')
		);
	} catch (error) {
		// show error notification
		notificationHandler(
			i18n.t('messages:notifications.error'),
			i18n.t('common:errors.common_error_message'),
			'error'
		);
	}
};
