import React, { Component } from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import debounce from 'lodash.debounce';
import arrayMove from 'array-move';
import services from 'services/services';
import { withTranslation } from 'react-i18next';

import {
	getNewSelectedData,
	dataToSelect,
	dataToSelected
} from './helpers/index';

// Import components
import { Prompt } from 'react-router-dom';

// Import helpers
import { convertErrors } from 'components/helpers/error';
import { capitalizeText } from 'components/helpers/convert';
import { compareArrays } from './helpers/index';
import { PRODUCT_SELECT_VOD_LIST_LENGTH } from 'components/helpers/variables';

// Import utilities
import { notificationHandler } from 'components/utilities/notifications/index';

const ProductsContext = React.createContext();
const ProductsConsumer = ProductsContext.Consumer;

class ProductsProvider extends Component {
	constructor(props) {
		super(props);

		this.state = {
			modal: {
				openModalText: '',
				tabs: [],
				selectedType: '',
				apiUrl: ''
			},
			select: {
				value: [],
				data: [],
				fetching: false,
				selected: {},
				error: false
			},
			submitting: false,
			buttonSubmitText: '',
			title: '',
			selectedData: [],
			unsavedChanges: false, // show unsaved changes dialog
			changeCounter: 0 // discards 1st change because it's fetched data
		};

		this.idSelector = 'uuid';
		this.titleSelector = 'title';
		this.labelSelector = 'label';
		this.lastFetchId = 0;
		this.fetchSelectAction = debounce(this.fetchSelectAction, 800);
	}

	/* --- Tabs --- */
	setDefaultTab = () => {
		const { tabs } = this.props;

		this.setState((state) => ({
			modal: {
				...state.modal,
				selectedType: tabs[0].type,
				apiUrl: tabs[0].apiUrl
			}
		}));
	};

	handleTabChange = ({ target: { value: type } }) => {
		const {
			modal: { tabs },
			select: { selected }
		} = this.state;
		const { apiUrl } = tabs.find((item) => item.type === type);
		// Add data to select according to current tab
		const selectedTabData = dataToSelect(
			selected,
			type,
			this.idSelector,
			this.titleSelector
		);

		this.setState((state) => ({
			modal: {
				...state.modal,
				selectedType: type,
				apiUrl
			},
			select: {
				...state.select,
				value: selectedTabData,
				data: [],
				fetching: false,
				error: false
			}
		}));
	};
	/* --- Tabs end --- */

	/* --- Select --- */
	fetchSelectAction = async (value) => {
		try {
			if (value.length >= 2) {
				const {
					modal: { apiUrl, selectedType }
				} = this.state;

				this.lastFetchId += 1;
				const fetchId = this.lastFetchId;

				this.setState(({ select }) => ({
					select: { ...select, data: [], fetching: true, error: false }
				}));

				// choose proper url based on selectedType
				let url = `${apiUrl}order[0][column]=1&order[0][dir]=asc&start=0&length=30&search[value]=${value}`;

				switch (selectedType) {
					case 'vod':
					case 'audio':
						url = `${apiUrl}order[0][column]=4&order[0][dir]=asc&start=0&length=${PRODUCT_SELECT_VOD_LIST_LENGTH}&columns[4][search][value]=${value}`;
						if (this.props.api === 'section')
							url = `${url}&withExpired=false&withEpisodesAndSeasons=false`;
						break;

					default:
						break;
				}

				// Get data from server and add them to select list
				let {
					data: { data }
				} = await services.get(url);

				if (fetchId !== this.lastFetchId) return;

				// Add uuid, type, title to provider items
				if (selectedType === 'provider') {
					data.forEach((item) => {
						item.title = item.name;
						item.uuid = item.slug;
						item.type = 'provider';
					});
				}

				// Remove not active products
				if (selectedType === 'vod') {
					data = data.filter(({ active }) => parseInt(active, 10) !== 0);
				}

				this.setState(({ select }) => ({
					select: { ...select, data, fetching: false }
				}));
			}
		} catch (error) {
			this.setState(({ select }) => ({
				select: { ...select, data: [], fetching: false, error: true }
			}));
		}
	};

	handleSelectChange = (value) => {
		const {
			modal: { selectedType },
			select: { data, selected }
		} = this.state;

		let newSelected = [];

		if (selected[selectedType]) {
			newSelected = dataToSelected(
				value,
				[...selected[selectedType], ...data],
				this.idSelector
			);
		} else {
			newSelected = dataToSelected(value, data, this.idSelector);
		}

		this.setState(({ select, select: { selected } }) => ({
			select: {
				...select,
				value,
				fetching: false,
				selected: { ...selected, [selectedType]: newSelected }
			}
		}));
	};

	clearSelectState = () =>
		this.setState({
			select: {
				value: [],
				data: [],
				fetching: false,
				selected: {},
				error: false
			}
		});
	/* --- Select End --- */

	/* --- Modal buttons --- */
	handleModalCancel = () => {
		// Remove value from select
		this.clearSelectState();

		// Activate the first tab
		this.setDefaultTab();
	};

	handleModalOk = () => {
		const {
			select: { selected },
			selectedData,
			modal: { tabs }
		} = this.state;

		const newSelectedData = getNewSelectedData(
			tabs,
			selected,
			selectedData,
			this.idSelector
		);

		this.setState(({ selectedData }) => ({
			selectedData: [...selectedData, ...newSelectedData],
			changeCounter: this.state.changeCounter + 1 //remembers that there's a change when adding product (especially when initial array is empty)
		}));

		// Remove value from select
		this.clearSelectState();

		// Activate the first tab
		this.setDefaultTab();
	};
	/* --- Modal buttons end --- */

	/* --- Drag sort --- */
	handleSortEnd = ({ oldIndex, newIndex }) =>
		this.setState(({ selectedData }) => ({
			selectedData: arrayMove(selectedData, oldIndex, newIndex)
		}));

	handleRemoveItem = (id) => {
		const { selectedData } = this.state;

		const newSelected = selectedData.filter(
			(item) => item[this.idSelector] !== id
		);

		this.setState({
			selectedData: newSelected
		});
	};
	/* --- Drag sort end --- */

	/* --- Component buttons --- */
	handleOnCancel = () => {
		// disables unsavedChanges
		this.setState({
			unsavedChanges: false
		});
		const { history } = this.props;
		history.goBack();
	};

	handleErrors = (error) => {
		// Convert Errors
		const errors = convertErrors(error.response.data.validator.errors);
		// CreateNotification
		Object.entries(errors).forEach(([key, value]) => {
			notificationHandler(capitalizeText(key), value, 'error', 8);
		});
		// Throw new errors
		return { ...errors };
	};

	handleOnSubmit = async () => {
		// disables unsavedChanges
		this.setState({
			unsavedChanges: false
		});
		try {
			const {
				id,
				api,
				apiSlug,
				notificationName,
				// history,
				t,
				createSubmitData
			} = this.props;
			const { selectedData } = this.state;

			const selection = createSubmitData(selectedData);

			this.setState({ submitting: true });

			await services.post(
				`${api}/${id}/${apiSlug ? apiSlug : 'syncProducts'}`,
				selection
			);

			this.setState({ submitting: false });

			notificationHandler(
				t('messages:notifications.edited'),
				`${t('messages:notifications.successfuly_edited')} ${t(
					`names:notification_names.${notificationName || api}`
				)}`
			);

			// Commented because functionality may be needed in the future
			// history.goBack();
		} catch (error) {
			this.setState({ submitting: false });
			return this.handleErrors(error);
		}
	};
	/* --- Component buttons end --- */

	componentDidMount() {
		const {
			openModalText,
			tabs,
			buttonSubmitText,
			title,
			initialData,
			sortableType
		} = this.props;
		this.setState({
			modal: {
				openModalText,
				tabs,
				selectedType: tabs[0].type,
				apiUrl: tabs[0].apiUrl
			},
			select: {
				value: [],
				data: [],
				fetching: false,
				selected: {},
				error: false
			},
			submitting: false,
			buttonSubmitText: buttonSubmitText,
			title: title,
			selectedData: initialData,
			unsavedChanges: false,
			changeCounter: 0
		});

		if (sortableType === 'list') {
			this.idSelector = 'id';
			this.titleSelector = 'name';
			this.labelSelector = 'label';
		}
	}

	componentDidUpdate(prevProps, prevState) {
		//if selectedData array has changed and it's not the first change ( first change is usually data fetching - only when list is empty it's not the case)
		// then there are some unsaved changes. Prompt them to user.
		if (!compareArrays(prevState.selectedData, this.state.selectedData)) {
			if (this.state.changeCounter !== 0) {
				this.setState({ unsavedChanges: true });
			}
			this.setState({ changeCounter: this.state.changeCounter + 1 });
		}

		if (prevProps.initialData !== this.props.initialData) {
			this.setState({
				selectedData: this.props.initialData
			});
		}
	}

	render() {
		return (
			<ProductsContext.Provider
				value={{
					...this.state,
					handleTabChange: this.handleTabChange,
					fetchSelectAction: this.fetchSelectAction,
					handleSelectChange: this.handleSelectChange,
					handleModalCancel: this.handleModalCancel,
					handleModalOk: this.handleModalOk,
					handleSortEnd: this.handleSortEnd,
					handleOnCancel: this.handleOnCancel,
					handleOnSubmit: this.handleOnSubmit,
					handleRemoveItem: this.handleRemoveItem,
					idSelector: this.idSelector,
					titleSelector: this.titleSelector,
					labelSelector: this.labelSelector,
					productsTitle: this.props.productsTitle
				}}
			>
				<Prompt
					when={this.state.unsavedChanges}
					message={this.props.t('messages:unsavedChanges')}
				/>
				{this.props.children}
			</ProductsContext.Provider>
		);
	}
}

export { ProductsConsumer, ProductsContext };

export default compose(withRouter, withTranslation())(ProductsProvider);
