import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

// Import components
import { UploadImage } from 'components/common/inputs';
import { IboxContentTitle, Wraper } from 'components/common/layout';
import ImagePreview from './ImagePreview';

// Import logic
import useImage from './hooks/useImage';

/**
 * Functional generic component that displays image. Designed to use across app.
 * @param {string} title - Title of image card
 * @param {function} fetchResourcesAction - Action to fetch image resources
 * @param {string} collectionType - Image collection type (cover | poster | logo | logo_inverted ...)
 * @param {string} mediaType - Type of media (channel | vod | packet ...)
 * @param {string} storePathSelector - type to define store path to file (channels | cod | categories | packets | banners ...) - check Redux for all types
 * @param {bool} isDarkBackground - is image background supposed to be dark
 * @param {number} imagesLimit - how many images user can upload
 * @param {bool} isImagesLimit - is there limit of images that user can upload
 * @returns {*}
 */

const Image = ({
	title,
	fetchResourcesAction,
	collectionType,
	mediaType,
	storePathSelector,
	isDarkBackground = false,
	customId = null,
	match: {
		params: { id }
	},
	productSubtype
}) => {
	const {
		productId,
		files,
		isLoaded,
		error,
		hasImage,
		fetchResources,
		assetRemove,
		conditionalClassName
	} = useImage({
		customId,
		id,
		fetchResourcesAction,
		storePathSelector,
		collectionType,
		isDarkBackground
	});

	return (
		<Wraper lg="12" error={error}>
			<IboxContentTitle
				title={title}
				isLoaded={isLoaded}
				classNameContent={conditionalClassName}
			>
				<div className={`h-150 d-flex flex-wrap ${conditionalClassName}`}>
					{isLoaded && (
						<>
							{hasImage ? (
								files.map(({ id, url }) => (
									<ImagePreview
										key={id}
										assetId={id}
										assetSource={url}
										mediaType={mediaType}
										productSubtype={productSubtype}
										productId={productId}
										collection={collectionType}
										assetRemove={assetRemove}
									/>
								))
							) : (
								<UploadImage
									collection={collectionType}
									mediaType={mediaType}
									modelId={productId}
									successCallback={fetchResources}
								/>
							)}
						</>
					)}
				</div>
			</IboxContentTitle>
		</Wraper>
	);
};

Image.propTypes = {
	title: PropTypes.string,
	fetchResourcesAction: PropTypes.func.isRequired,
	collectionType: PropTypes.string.isRequired,
	mediaType: PropTypes.string.isRequired,
	storePathSelector: PropTypes.string.isRequired,
	darkBackground: PropTypes.bool,
	customId: PropTypes.string,
	match: PropTypes.shape({
		params: PropTypes.shape({ id: PropTypes.string })
	}),
	isDarkBackground: PropTypes.bool,
	productSubtype: PropTypes.string.isRequired
};

export default withRouter(Image);
