import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
	fetchChannelsSystem,
	dictionaryCastTypes,
	dictionaryPlatformsTypes
} from 'store/actions';

// Validate
import validate from './validation';

// Import translation
import { useTranslation } from 'react-i18next';

// Import utilities
import { submitForm } from 'components/utilities/form';

// Import variables
import { SYSTEM_CUSTOM_NAMES } from 'components/helpers/variables';

// Import components
import { FormTemplate } from 'components/common/templates';
import { FormButtons } from 'components/common/buttons';
import Fields from './fields/Fields';

const ChannelsSystemsForm = ({
	history,
	fetchChannelsSystem,
	dictionaryCastTypes,
	error,
	isLoaded,
	initialValues,
	systemName,
	castList,
	platformList,
	dictionaryPlatformsTypes
}) => {
	const { t } = useTranslation();

	useEffect(() => {
		dictionaryCastTypes();
		dictionaryPlatformsTypes();
		// eslint-disable-next-line
	}, []);

	const handleOnSubmit = ({ isEdit }) => async (values) =>
		// Submit the form with field values
		await submitForm({
			history,
			isEdit,
			itemID: values.id,
			resources: values,
			api: 'channel/systemFields',
			notificationName: 'system'
		});

	return (
		<FormTemplate
			fetchResourceToEdit={fetchChannelsSystem}
			error={error}
			isLoaded={isLoaded}
			fetchActionResources={{ systemName }}
			iboxContentTitle={`${t('channels:system.config_title')} ${
				SYSTEM_CUSTOM_NAMES[systemName]
			}`}
		>
			{({ isEdit, itemID, checkedPermissions, decorators }) => (
				<Form
					initialValues={initialValues}
					onSubmit={handleOnSubmit({ isEdit, itemID })}
					validate={validate}
					decorators={decorators}
					render={({ handleSubmit, submitting }) => (
						<form onSubmit={handleSubmit}>
							<Fields
								submitting={submitting}
								casts={castList}
								platforms={platformList}
								checkedPermissions={checkedPermissions}
							/>

							<FormButtons
								isButtonDisabled={submitting || error}
								path="channels"
								buttonText={t('common:buttons.submit')}
							/>
						</form>
					)}
				/>
			)}
		</FormTemplate>
	);
};

ChannelsSystemsForm.propTypes = {
	history: PropTypes.object.isRequired,
	initialValues: PropTypes.object.isRequired,
	fetchChannelsSystem: PropTypes.func.isRequired,
	dictionaryCastTypes: PropTypes.func.isRequired,
	error: PropTypes.bool.isRequired,
	isLoaded: PropTypes.bool.isRequired,
	systemName: PropTypes.string,
	castList: PropTypes.array.isRequired,
	dictionaryPlatformsTypes: PropTypes.func.isRequired,
	platformList: PropTypes.array.isRequired
};

const mapStateToProps = ({
	channels: { system },
	dictionary: { castTypes, platformTypes }
}) => {
	return {
		initialValues: system.edit,
		error: system.error,
		isLoaded: system.isLoaded,
		castList: castTypes,
		platformList: platformTypes
	};
};

export default compose(
	connect(mapStateToProps, {
		fetchChannelsSystem,
		dictionaryCastTypes,
		dictionaryPlatformsTypes
	}),
	withRouter
)(ChannelsSystemsForm);
