import React from 'react';

// Import components
import ChannelsSystemForm from './channels_systems_form/ChannelsSystemsForm';
import ChannelsPlatformsForm from './channels_platforms_form/ChannelsPlatformsForm';

// Import variables
import { SYSTEM_TYPES } from 'components/helpers/variables';

function ChannelsOBOX() {
	return (
		<>
			<ChannelsSystemForm systemName={SYSTEM_TYPES.obox} />
			<ChannelsPlatformsForm systemName={SYSTEM_TYPES.obox} />
		</>
	);
}

export default ChannelsOBOX;
