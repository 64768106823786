import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Import components
import { Col, FormGroup, Label } from 'reactstrap';
import { Editor } from '@tinymce/tinymce-react';

// Import init
import { init_options } from 'components/common/inputs/texteditor/helpers';

// Import helpers
import { REQUIRED_MARK } from 'components/helpers/variables';

const TinyTextEditorField = ({
	input,
	id,
	label,
	labelColumn = 2,
	inputColumn = 10,
	children,
	fieldInfo = null,
	meta: { touched, error, submitError },
	disabledAddingImage,
	disabledAddingMedia,
	warningInfo = false,
	disabled,
	requiredField = false,
	...inputProps
}) => {
	const { t } = useTranslation();

	const editorRef = useRef(null);

	const [characterCount, setCharacterCount] = useState(0);

	useEffect(() => {
		charactersCount();
	}, [input.value]);

	const charactersCount = () => {
		if (editorRef.current) {
			// return editorRef.current.plugins.wordcount.body.getCharacterCountWithoutSpaces();
			const characters = editorRef.current.plugins.wordcount.body.getCharacterCountWithoutSpaces();
			setCharacterCount(characters);
		}
	};

	const handleInit = (evt, editor) => {
		editorRef.current = editor;
	};

	const handleEditorChange = (content) => {
		// Update input value
		input.onChange(content);
	};

	// Render field info if there aren't any errors
	const renderFieldInfo = () => {
		if (touched && (error || submitError)) return null;
		if (fieldInfo) {
			return (
				<span className="input-info m-b-sm">
					<i className="fa fa-info-circle m-r-sm"></i>
					{fieldInfo}
				</span>
			);
		}
	};

	return (
		<FormGroup
			data-testid={`form-group-${input.name}`}
			row
			className={`${touched && error && 'has-error'}`}
		>
			<Label
				for={id}
				sm={labelColumn}
				className="col-form-label col-form-label-lg"
			>
				{label} {requiredField && REQUIRED_MARK}
			</Label>
			<Col sm={inputColumn}>
				<Editor
					onInit={handleInit}
					apiKey={process.env.REACT_APP_TINYMCE_KEY}
					value={input.value}
					init={init_options({
						disabledAddingImage,
						disabledAddingMedia,
						disabled
					})}
					onEditorChange={handleEditorChange}
					disabled={disabled}
				/>

				{touched && (error || submitError) && (
					<span
						data-testid={`input-error-${input.name}`}
						className="input-error d-block"
					>
						{error || submitError}
					</span>
				)}
				<span className="d-block">
					{t('common:characters_count')}: {characterCount}
				</span>
				{warningInfo && (
					<span className="input-warning">
						{t('common:form.fields.validation.value_too_long')}
					</span>
				)}
				{renderFieldInfo()}
			</Col>
			{children}
		</FormGroup>
	);
};

TinyTextEditorField.propTypes = {
	input: PropTypes.object.isRequired,
	label: PropTypes.string,
	id: PropTypes.string.isRequired,
	labelColumn: PropTypes.number,
	inputColumn: PropTypes.number,
	children: PropTypes.object,
	meta: PropTypes.object,
	fieldInfo: PropTypes.string,
	requiredField: PropTypes.bool
};

export default TinyTextEditorField;
