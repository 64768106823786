import React from 'react';
import i18n from 'i18next';

// Import variables
import {
	SYSTEM_TYPES,
	SYSTEM_TYPES_FROM_ENV
} from 'components/helpers/variables';

// Import helpers
import { applySystemTabs } from 'components/helpers/tabs_helpers/tabs_helpers';

// Import components
import EpgForm from '../../epg_form/EpgForm';
import ProgramTVO from '../../epg_contexts/ProgramTVO';
import ProgramVOTT from '../../epg_contexts/ProgramVOTT';
import ProgramOBOX from '../../epg_contexts/ProgramOBOX';

const systemEpgTabs = {
	[SYSTEM_TYPES.ott]: {
		name: i18n.t('common:tabs.ott'),
		path: 'ott',
		disableOnCreate: true,
		component: <ProgramVOTT />,
		className: 'tab--bold'
	},
	[SYSTEM_TYPES.tvonline]: {
		name: i18n.t('common:tabs.tvonline'),
		path: 'tvonline',
		disableOnCreate: true,
		component: <ProgramTVO />,
		className: 'tab--bold'
	},
	[SYSTEM_TYPES.obox]: {
		name: i18n.t('common:tabs.obox'),
		path: 'obox',
		disableOnCreate: true,
		component: <ProgramOBOX />,
		className: 'tab--bold'
	}
};

const basicEpgTabs = [
	{
		name: i18n.t('common:tabs.metadata'),
		path: 'metadata',
		disableOnCreate: false,
		component: <EpgForm />
	}
];

export const epgTabs = () => {
	return applySystemTabs(SYSTEM_TYPES_FROM_ENV, systemEpgTabs, basicEpgTabs);
};
