import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as actions from 'store/actions';
import { withRouter } from 'react-router-dom';
import { Alert } from 'antd';

// Import utilities
import { useForm } from 'components/utilities/form';

// Import components
import Loader from 'components/common/loaders/Loader';
import { Wraper } from 'components/common/layout';
import ConfigurationForm from './form/ConfigurationForm';

const Configuration = ({
	location,
	match,
	fetchConfiguration,
	error,
	isLoaded
}) => {
	// Use useForm to fetch resources
	useForm({
		location,
		match,
		fetchFormResources: [],
		fetchResourceToEdit: fetchConfiguration
	});

	return (
		<>
			<Wraper lg="12" error={error}>
				<Loader isLoaded={isLoaded}>
					<React.Fragment>
						{error && (
							<Alert
								showIcon
								message="Error"
								type="error"
								description="An error has occurred and the data can not be retrieved"
								className="m-t-sm"
							/>
						)}
						<ConfigurationForm />
					</React.Fragment>
				</Loader>
			</Wraper>
		</>
	);
};

Configuration.propTypes = {
	location: PropTypes.object,
	match: PropTypes.object,
	fetchConfiguration: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		error: state.configuration.form.error,
		isLoaded: state.configuration.form.list.isLoaded
	};
};

export default React.memo(
	compose(connect(mapStateToProps, actions), withRouter)(Configuration)
);
