import i18n from 'i18next';

export const types = [
	{
		name: i18n.t('epg:blackout_typs.streaming'),
		value: 'streaming'
	},
	{
		name: i18n.t('epg:blackout_typs.catchup'),
		value: 'catchup'
	},
	{
		name: i18n.t('epg:blackout_typs.npvr'),
		value: 'npvr'
	}
];

export const systemTypes = [
	{
		name: i18n.t('epg:system_types.all'),
		value: 'all'
	},
	{
		name: i18n.t('epg:system_types.dtv'),
		value: 'tvonline'
	},
	{
		name: i18n.t('epg:system_types.ott'),
		value: 'ott'
	}
];

export const platformTypes = [
	{
		name: i18n.t('epg:platform_types.all'),
		value: 'all'
	},
	{
		name: i18n.t('epg:platform_types.primary'),
		value: 'platform_primary'
	},
	{
		name: i18n.t('epg:platform_types.secondary'),
		value: 'platform_secondary'
	}
];
