// Import translation
import i18n from 'i18next';
import moment from 'moment';

// Import helpers
import { switchInfo } from '../helpers';

export const defaultFieldValues = {
	description: null,
	active: false,
	promotions_period: null,
	promotion_type: 'percentage',
	promotion_value: 0,
	since: null,
	till: null,
	users_specification: 'all_users',
	products_specification: 'all_products',
	trigger: 'auto',
	code: '',
	limit: null,
	entry_condition: false,
	entry_condition_products_specification: 'inherited',
	active_obox: false,
	active_ott: false,
	active_tvonline: true
};

export const BASE_FIELDS = ({ isEdit, currentValues, checkedPermissions }) => [
	{
		name: 'name',
		label: i18n.t('promotions:form:fields.name'),
		type: 'text',
		requiredField: true
	},
	{
		name: 'description',
		label: i18n.t('promotions:form:fields.description'),
		type: 'textarea'
	},
	{
		name: 'active',
		label: i18n.t('promotions:form:fields.active'),
		type: 'checkbox',
		disabled: !checkedPermissions.canActivateProduct
	},
	{
		// Promotion period is field that handles since and till dates in single date range picker
		name: 'promotion_period',
		label: i18n.t('promotions:form:fields.promotion_period'),
		placeholder: [
			i18n.t('promotions:form:placeholder.promotion_period_since'),
			i18n.t('promotions:form:placeholder.promotion_period_till')
		],
		type: 'range',
		showTime: {
			defaultValue: [
				moment('00:00:00', 'HH:mm:ss'),
				moment('23:59:59', 'HH:mm:ss')
			]
		},
		rangeFormat: 'YYYY-MM-DD HH:mm:ss'
	},
	{
		name: 'promotion_type',
		label: i18n.t('promotions:form:fields.promotion_type'),
		type: 'select',
		fieldInfo: i18n.t(`${switchInfo(currentValues['promotion_type'])}`)
	},
	{
		name: 'promotion_value',
		label: i18n.t('promotions:form:fields.promotion_value'),
		type: 'number'
	},
	{
		name: 'users_specification',
		label: i18n.t('promotions:form:fields.users_specification'),
		type: 'select',
		fieldInfo: i18n.t(
			`${switchInfo(
				currentValues['users_specification'],
				'users_specification'
			)}`
		)
	},
	{
		name: 'products_specification',
		label: i18n.t('promotions:form:fields.products_specification'),
		type: 'select',
		fieldInfo: i18n.t(`${switchInfo(currentValues['products_specification'])}`)
	},
	{
		name: 'trigger',
		label: i18n.t('promotions:form:fields.trigger'),
		type: 'select',
		fieldInfo: i18n.t(`${switchInfo(currentValues['trigger'])}`)
	},
	{
		name: 'code',
		label: i18n.t('promotions:form:fields.code'),
		type: 'text-w-button-async',
		isVisible: currentValues['trigger'] === 'code',
		url: '/promotions/code/generate',
		buttonText: i18n.t('promotions:buttons.generate_code')
	},
	{
		name: 'global_limit',
		label: i18n.t('promotions:form:fields.limit'),
		type: 'number',
		fieldInfo: i18n.t('promotions:form:fields_info.limit')
	},
	// only visible when editing existing promotion
	{
		name: 'created_at',
		label: i18n.t('promotions:form:fields.created_at'),
		type: 'text',
		disabled: true,
		isVisible: !isEdit
	},
	{
		name: 'updated_at',
		label: i18n.t('promotions:form:fields.updated_at'),
		type: 'text',
		disabled: true,
		isVisible: !isEdit
	},
	{
		name: 'entry_condition',
		label: i18n.t('promotions:form:fields.entry_condition'),
		type: 'checkbox'
	},
	{
		name: 'entry_condition_purchases_threshold',
		label: i18n.t('promotions:form:fields.entry_condition_purchases_threshold'),
		type: 'number',
		isVisible: currentValues['entry_condition'] === true,
		fieldInfo: i18n.t(
			'promotions:form:fields_info.entry_condition_purchases_threshold'
		)
	},
	{
		name: 'entry_condition_products_specification',
		label: i18n.t(
			'promotions:form:fields.entry_condition_products_specification'
		),
		type: 'select',
		isVisible: currentValues['entry_condition'] === true,
		fieldInfo: i18n.t(
			`${switchInfo(
				currentValues['entry_condition_products_specification'],
				'entry_condition'
			)}`
		)
	},
	{
		name: 'entry_condition_in_ttl',
		label: i18n.t('promotions:form:fields.entry_condition_in_ttl'),
		type: 'number',
		isVisible: currentValues['entry_condition'] === true,
		fieldInfo: i18n.t('promotions:form:fields_info.entry_condition_in_ttl')
	},
	{
		name: 'entry_condition_limit',
		label: i18n.t('promotions:form:fields.entry_condition_limit'),
		type: 'number',
		isVisible: currentValues['entry_condition'] === true,
		fieldInfo: i18n.t('promotions:form:fields_info.entry_condition_limit')
	}
];
