import services from 'services/services';
import * as types from '../types';
import { setRequestUrl } from 'store/actions';

// Import helpers
import {
	checkFetchErrors,
	queryParams
} from '../helpers_actions/convert_helpers';

// ******************** FETCH SECTIONS GROUP - TABLE DATA ********************
export const fetchSectionsGroups = (options) => async (dispatch, getState) => {
	const {
		sections_groups: {
			table: { allTypesData }
		}
	} = getState();

	// fetch data only when allTypesData (this is unfiltered data fetched from server)
	// is empty. This feature is to stop refetchnig data when there is empty array assigned to data.
	// Empty array is assigned when local section group filters return no data.
	if (!allTypesData.length) {
		try {
			// Dispatch a loading action
			dispatch({
				type: types.FETCH_SECTIONS_GROUPS_LOADING,
				payload: true
			});

			const {
				sections_groups: {
					table: {
						columns,
						options: { startIndex: startTableIndex, page }
					}
				}
			} = getState();

			const startIndex =
				options.page === page ? startTableIndex : options.startIndex;

			// In this project, we need only data with system tvonline
			const url = `/section/group/list?${queryParams(
				options,
				null,
				columns,
				startIndex
			)}&columns[3][search][value]=tvonline`;

			const { data } = await services.get(url);
			// Calculate pages
			// null is assigned to disable pagination rendering
			const pages = null;

			// Dispatch an action with data
			dispatch({
				type: types.FETCH_SECTIONS_GROUPS_SUCCESS,
				payload: { ...data, pages, page: options.page, startIndex }
			});

			// save request url in redux
			setRequestUrl(url)(dispatch);
		} catch (error) {
			dispatch({
				type: types.FETCH_SECTIONS_GROUPS_ERROR,
				payload: checkFetchErrors(error)
			});
		}
	}
};

// ******************** SET SECTIONS GROUPS TYPE ********************
export const setSectionsGroupsType = (type) => {
	return {
		type: types.SET_SECTIONS_GROUPS_TYPE,
		payload: type
	};
};
