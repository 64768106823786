// Import translation
import i18n from 'i18next';

export const defaultFieldValues = {
	active_obox: false,
	active_ott: false,
	active_tvonline: true
};

export const BASE_FIELDS = () => [
	{
		name: 'name',
		label: i18n.t('categories:fields.name'),
		type: 'text',
		requiredField: true
	},
	{
		name: 'slug',
		label: i18n.t('categories:fields.slug'),
		type: 'text',
		requiredField: true
	},
	{
		name: 'order',
		label: i18n.t('categories:fields.order'),
		type: 'number',
		step: 1
	}
];
