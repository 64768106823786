// Import translation
import i18n from 'i18next';

export default ({
	title,
	message,
	platforms,
	systems,
	send_to_all,
	sn_from,
	sn_to,
	sn_list
}) => {
	const errors = {};

	// ------------------ Title ------------------
	if (!title) {
		errors.title = i18n.t('notifications:form:validation.title_required');
	}
	// ------------------ Message ------------------
	if (!message) {
		errors.message = i18n.t('notifications:form:validation.message_required');
	}
	// ------------------ Platform ------------------
	if (!platforms) {
		errors.platforms = i18n.t(
			'notifications:form:validation.platforms_required'
		);
	}
	// ------------------ System ------------------
	if (!systems) {
		errors.systems = i18n.t('notifications:form:validation.systems_required');
	}
	// ------------------ SN - Serial Number ------------------
	if (send_to_all === false) {
		if (!sn_list && !sn_from && !sn_to) {
			errors.sn_list = i18n.t('notifications:form:validation.sn_required');
			errors.sn_from = i18n.t('notifications:form:validation.sn_required');
			errors.sn_to = i18n.t('notifications:form:validation.sn_required');
		} else if (!sn_list) {
			if (!sn_from) {
				errors.sn_from = i18n.t(
					'notifications:form:validation.sn_from_required'
				);
			} else if (!sn_to) {
				errors.sn_to = i18n.t('notifications:form:validation.sn_to_required');
			}
		}
	}

	return errors;
};
