import React from 'react';
import PropTypes from 'prop-types';
import { Button, Select } from 'antd';

// Import helpers
import { types, systemTypes, platformTypes } from './helpers';

const { Option } = Select;

const ControlButtons = (props) => {
	const {
		handleTurnOn,
		handleTurnOff,
		clearAllSelection,
		selectType,
		selectionLength,
		actionButtonOnTxt,
		actionButtonOffTxt,
		clearButtonTxt,
		selectTypeTxt,
		type,
		errors,
		systems,
		platform,
		selectSystems,
		selectPlatform,
		selectSystemsTxt,
		selectPlatformTxt
	} = props;

	const isItemsChecked = selectionLength > 0;
	const selectStyle = { width: 168, verticalAlign: '-1px' };

	return (
		<React.Fragment>
			<Select
				className={`btn-table-select ${errors.type}`}
				style={selectStyle}
				placeholder={selectTypeTxt}
				disabled={!isItemsChecked}
				onChange={selectType}
				value={type}
			>
				{types.map(({ value, name }) => (
					<Option key={value} value={value}>
						{name}
					</Option>
				))}
			</Select>
			<Select
				className={`btn-table-select ${errors.systems}`}
				style={selectStyle}
				placeholder={selectSystemsTxt}
				disabled={!isItemsChecked}
				onChange={selectSystems}
				value={systems}
			>
				{systemTypes.map(({ value, name }) => (
					<Option key={value} value={value}>
						{name}
					</Option>
				))}
			</Select>
			<Select
				className={`btn-table-select ${errors.platform}`}
				style={selectStyle}
				placeholder={selectPlatformTxt}
				disabled={!isItemsChecked}
				onChange={selectPlatform}
				value={platform}
			>
				{platformTypes.map(({ value, name }) => (
					<Option key={value} value={value}>
						{name}
					</Option>
				))}
			</Select>
			<Button
				className="btn-table-select"
				type="danger"
				onClick={handleTurnOn}
				disabled={!isItemsChecked}
			>
				{actionButtonOnTxt}
			</Button>
			<Button
				className="btn-table-select"
				type="primary"
				onClick={handleTurnOff}
				disabled={!isItemsChecked}
			>
				{actionButtonOffTxt}
			</Button>
			<Button
				className="btn-table-select"
				onClick={clearAllSelection}
				disabled={!isItemsChecked}
			>
				{clearButtonTxt}
			</Button>
		</React.Fragment>
	);
};

ControlButtons.propTypes = {
	handleTurnOn: PropTypes.func.isRequired,
	handleTurnOff: PropTypes.func.isRequired,
	clearAllSelection: PropTypes.func.isRequired,
	selectType: PropTypes.func.isRequired,
	selectionLength: PropTypes.number.isRequired,
	actionButtonOnTxt: PropTypes.string.isRequired,
	actionButtonOffTxt: PropTypes.string.isRequired,
	clearButtonTxt: PropTypes.string.isRequired,
	selectTypeTxt: PropTypes.string.isRequired,
	type: PropTypes.string,
	errors: PropTypes.object,
	systems: PropTypes.string,
	platform: PropTypes.string,
	selectSystems: PropTypes.func.isRequired,
	selectPlatform: PropTypes.func.isRequired,
	selectSystemsTxt: PropTypes.string.isRequired,
	selectPlatformTxt: PropTypes.string.isRequired
};

export default ControlButtons;
