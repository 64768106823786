import React from 'react';
import { Link } from 'react-router-dom';

// Import translation
import i18n from 'i18next';

// Import components
import ActiveIcon from 'components/utilities/table/render_icons/ActiveIcon';

export const createColumns = (handleSelectItem, isReadOnly) => [
	{
		Header: i18n.t('menu:table_columns.name'),
		accessor: 'name',
		Cell: ({
			row: {
				_original: { id, name }
			}
		}) => <Link to={`/panel/menus/edit/${id}/metadata`}>{name}</Link>
	},
	{
		Header: i18n.t('menu:table_columns.menu_element'),
		accessor: 'model_id',
		Cell: ({
			row: {
				_original: { model_id, link }
			}
		}) => <span>{model_id || link}</span>
	},
	{
		Header: i18n.t('menu:table_columns.order'),
		accessor: 'order'
	},
	{
		Header: i18n.t('menu:table_columns.created_at'),
		accessor: 'created_at',
		sortable: false
	},
	{
		Header: i18n.t('menu:table_columns.updated_at'),
		accessor: 'updated_at',
		sortable: false
	},
	{
		Header: i18n.t('menu:table_columns.platform_group'),
		accessor: 'platform_group',
		sortable: false,
		Cell: ({
			row: {
				_original: { platform_group }
			}
		}) => {
			const platform =
				platform_group || i18n.t('menu:platform_group.no_platform');

			return <span>{platform}</span>;
		}
	},
	{
		Header: i18n.t('common:table_columns.active'),
		accessor: 'active',
		width: 75,
		className: 'text-center',
		sortable: false,
		Cell: ({ row: { _original } }) => <ActiveIcon active={_original.active} />
	},
	{
		Header: i18n.t('common:table_columns.delete'),
		accessor: 'delete',
		width: 60,
		className: 'text-center',
		sortable: false,
		Cell: ({
			row: {
				_original: { id }
			}
		}) =>
			!isReadOnly && (
				<button
					onClick={() => handleSelectItem(id)}
					className="btn btn-xs btn-primary"
				>
					<i className="fa fa-trash" />
				</button>
			)
	}
];
