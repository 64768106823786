/**
 * Function that filters system tabs based on env REACT_APP_SYSTEMS variable
 * and inserts them after metadata tab.
 * @param {array} systemTypesEnv - types of systems that are defined in env
 * @param {object} systemTabs - object with types defined for systems (eg. ChannelTVO)
 * @param {array} basicTabs - basic tabs that are always rendered
 */
export const applySystemTabs = (
	systemTypesEnv,
	systemTabs,
	basicTabs,
	insertionIndex = 1
) => {
	// create system tabs array based on env systems
	let filteredSystemTabs = systemTypesEnv
		.map((system) => systemTabs[system.toLowerCase()])
		.filter((system) => system);

	// set final tabs with systems
	const appliedTabs = [...basicTabs];
	// insert systems tabs into basic tabs
	appliedTabs.splice(insertionIndex, 0, ...filteredSystemTabs);

	return appliedTabs;
};
