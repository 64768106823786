import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import { dictionaryPlatformsTypes } from 'store/actions';
import { Modal } from 'antd';
import { useHistory } from 'react-router-dom';

// Import translation
import { useTranslation } from 'react-i18next';

// Import utilities
import { submitForm } from 'components/utilities/form';

// Validate
import validate from './validation';

// Import components
import { FormTemplate } from 'components/common/templates';
import { FormButtons } from 'components/common/buttons';
import Fields from './fields/Fields';

// Import helpers
import { formatSNList } from 'components/helpers/systems_platforms';

const NotificationsForm = ({
	platformList,
	systemList,
	dictionaryPlatformsTypes
}) => {
	useEffect(() => {
		dictionaryPlatformsTypes();
		// eslint-disable-next-line
	}, []);

	const history = useHistory();

	const { t } = useTranslation();

	const handleOnSubmit = ({ isEdit, itemID }) => async (values, form) => {
		const { reset, resetFieldState } = form;
		const resources = new FormData();
		const { sn_from, sn_to, sn_list } = values;
		// field names to pass to handle submit function to reset those fields state
		const fieldNames = Object.keys(values);

		Object.keys(values).forEach((key) => {
			switch (key) {
				case 'file':
					if (typeof values[key] === 'string' || values[key].length === 0)
						break;
					resources.append(key, values.file[0]);
					break;
				case 'send_to_all':
					resources.append(key, values[key] === false ? 0 : 1);
					break;
				case 'sn_list':
					resources.append(key, formatSNList(values[key]));
					break;
				case 'systems':
					values[key].forEach((value) => {
						resources.append(`${key}[]`, value);
					});
					break;
				case 'platforms':
					values[key].forEach((value) => {
						resources.append(`${key}[]`, value);
					});
					break;
				default:
					resources.append(key, values[key]);
					break;
			}
		});

		const snData = [sn_from, sn_to, sn_list];
		const isSnData = snData.every((item) => item);

		if (isSnData) {
			handleSubmitForm({
				resources,
				isEdit,
				itemID,
				reset,
				resetFieldState,
				fieldNames
			});
		} else {
			sendWithoutSnConfirm({
				resources,
				isEdit,
				itemID,
				reset,
				resetFieldState,
				fieldNames
			});
		}
	};

	const handleSubmitForm = async ({
		resources,
		reset,
		resetFieldState,
		fieldNames
	}) => {
		await submitForm({
			history,
			isEdit: false, //hardcoded because api is "special" here
			itemID: 'send', //hardcoded because api is "special" here
			resources,
			api: 'subscriber/notifications',
			notificationName: 'notification'
		});
		// reset form after sending form
		fieldNames.forEach((fieldName) => resetFieldState(fieldName));
		reset();
	};

	const sendWithoutSnConfirm = ({
		resources,
		isEdit,
		itemID,
		reset,
		resetFieldState,
		fieldNames
	}) => {
		Modal.confirm({
			title: t(`users_notifications:modal.attention`),
			content: t(`users_notifications:modal.no_sn_data`),
			okText: t('common:yes'),
			cancelText: t('common:no'),
			onOk: () => {
				handleSubmitForm({
					resources,
					isEdit,
					itemID,
					reset,
					resetFieldState,
					fieldNames
				});
				Modal.destroyAll();
			}
		});
	};

	const formInitialValues = {
		send_to_all: false
	};

	return (
		<FormTemplate
			iboxContentTitle={t('notifications:form.notifications_title')}
		>
			{({ isEdit, itemID, decorators }) => (
				<Form
					initialValues={formInitialValues}
					onSubmit={handleOnSubmit({ isEdit, itemID })}
					validate={validate}
					decorators={decorators}
					render={({ handleSubmit, submitting }) => (
						<form onSubmit={handleSubmit}>
							<Fields
								submitting={submitting}
								platforms={platformList}
								systems={systemList}
							/>

							<FormButtons
								isButtonDisabled={submitting}
								path="notifications"
								buttonText={t('common:buttons.submit')}
							/>
						</form>
					)}
				/>
			)}
		</FormTemplate>
	);
};

NotificationsForm.propTypes = {
	dictionaryPlatformsTypes: PropTypes.func.isRequired,
	platformList: PropTypes.array.isRequired,
	systemList: PropTypes.array.isRequired
};

const mapStateToProps = ({
	dictionary: { platformTypes, subscriberSystem }
}) => {
	return {
		platformList: platformTypes,
		systemList: subscriberSystem
	};
};

export default connect(mapStateToProps, { dictionaryPlatformsTypes })(
	NotificationsForm
);
