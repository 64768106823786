import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchChannel, fetchCategories } from 'store/actions';

// Import utilities
import { submitForm } from 'components/utilities/form';

// Import components
import { FormTemplate } from 'components/common/templates';
import MetadataForm from '../channels_common/metadata_form/MetadataForm';

// Import helpers
import {
	liveCategoriesOptions,
	convertDataToNames,
	convertToIds
} from 'components/helpers/genres_helpers/helpers';

const ChannelsForm = ({
	history,
	fetchChannel,
	fetchCategories,
	error,
	isLoaded,
	initialValues,
	genresList
}) => {
	useEffect(() => {
		// Fetch categories with default params to feed genres combobox
		fetchCategories(liveCategoriesOptions, null, null);
		// eslint-disable-next-line
	}, []);

	const handleOnSubmit = ({ isEdit, itemID }) => async (values) => {
		const { genres } = values;

		// selected genres mapped to array of ids
		const genresId = convertToIds(genresList, genres);

		const resources = {
			...values,
			genres: genresId
		};

		// Submit the form with field values
		return await submitForm({
			history,
			isEdit,
			itemID,
			resources,
			api: 'channel',
			redirectPath: 'channels'
		});
	};

	const { genres } = initialValues;

	// convert genres objects of currently edited channel to array of genres names
	const currentChannelGenres = convertDataToNames(genres);
	const genresListNames = convertDataToNames(genresList);

	// form initial values
	const formInitialValues = {
		...initialValues,
		genres: currentChannelGenres
	};

	return (
		// Dispatch fetchChannelCategories, fetchChannel actions in in channels_form
		<FormTemplate
			fetchResourceToEdit={fetchChannel}
			error={error}
			isLoaded={isLoaded}
		>
			{({ isEdit, itemID, decorators }) => (
				<MetadataForm
					genres={genresListNames}
					decorators={decorators}
					isEdit={isEdit}
					itemID={itemID}
					initialValues={formInitialValues}
					handleOnSubmit={handleOnSubmit}
					path="channels"
				/>
			)}
		</FormTemplate>
	);
};

ChannelsForm.propTypes = {
	history: PropTypes.object.isRequired,
	initialValues: PropTypes.object.isRequired,
	fetchCategories: PropTypes.func.isRequired,
	fetchChannel: PropTypes.func.isRequired,
	error: PropTypes.bool.isRequired,
	isLoaded: PropTypes.bool.isRequired,
	genresList: PropTypes.array.isRequired
};

const mapStateToProps = ({
	channels: { form },
	categories: {
		table: { data }
	}
}) => ({
	initialValues: form.edit,
	error: form.error,
	isLoaded: form.isLoaded,
	genresList: data
});

export default compose(
	connect(mapStateToProps, {
		fetchCategories,
		fetchChannel
	}),
	withRouter
)(ChannelsForm);
