import React from 'react';
import PropTypes from 'prop-types';

// Import translation
import { useTranslation } from 'react-i18next';

// Import utilites
import { renderFields } from 'components/utilities/form/renderFields';

// Import fields
import { BOX_FIELDS, SECONDARY_SCREENS_FIELDS } from './input_fields';

const Fields = ({ submitting }) => {
	const { t } = useTranslation();

	return (
		<React.Fragment>
			<h3>{t('epg:system:field_groups.primary_fields')}</h3>
			<div className="hr-line-solid">&nbsp;</div>
			{BOX_FIELDS.map((item, index) =>
				renderFields({ item, index, submitting })
			)}

			<h3>{t('epg:system:field_groups.secondary_fields')}</h3>
			<div className="hr-line-solid">&nbsp;</div>
			{SECONDARY_SCREENS_FIELDS.map((item, index) =>
				renderFields({ item, index, submitting })
			)}
		</React.Fragment>
	);
};

Fields.propTypes = {
	submitting: PropTypes.bool.isRequired
};

export default Fields;
