import React from 'react';
import { Field } from 'react-final-form';
import { Select } from 'antd';

// Import components
import {
	InputLabel,
	InputLabelButtonAsync,
	InputLabelButtonSync,
	DatePickerLabel,
	TimePickerLabel,
	TextareaLabel,
	TinyTextEditorField,
	MultiSelectLabel,
	SelectLabel,
	CheckboxLabel,
	CheckboxLabelInverted,
	InputFileLabel,
	RangePickerLabel,
	MultiselectLabelCustomChange,
	ProviderFieldMultiSelectLabel
} from 'components/common/inputs';

export const renderFields = ({
	item,
	index,
	submitting,
	selectResources,
	onSearch
}) => {
	// some fields have isVisible flag. If it's undefined, create and set flag to true
	if (!item.hasOwnProperty('isVisible')) {
		item.isVisible = true;
	}

	switch (item.type) {
		case 'textarea':
			return (
				item.isVisible && (
					<div key={`${item.name}-${index}`}>
						<Field
							{...item}
							key={`${item.name}-${index}`}
							disabled={submitting || item.disabled}
							name={item.name}
							type="textarea"
							placeholder={item.placeholder}
							label={item.label}
							id={item.name}
							requiredField={item.requiredField}
							component={TextareaLabel}
							fieldInfo={item.fieldInfo}
							labelColumn={item.labelColumn}
							inputColumn={item.inputColumn}
							parse={(value) => value}
						/>
						<div className="hr-line-dashed">&nbsp;</div>
					</div>
				)
			);

		case 'editor':
			return (
				item.isVisible && (
					<div key={`${item.name}-${index}`}>
						<Field
							{...item}
							key={`${item.name}-${index}`}
							disabled={!!(item.disabled || submitting)}
							name={item.name}
							id={item.name}
							requiredField={item.requiredField}
							label={item.label}
							labelColumn={item.labelColumn}
							inputColumn={item.inputColumn}
							fieldInfo={item.fieldInfo}
							disabledAddingImage={item.disabledAddingImage}
							disabledAddingMedia={item.disabledAddingMedia}
							component={TinyTextEditorField}
							parse={(value) => value}
						/>
						<div className="hr-line-dashed">&nbsp;</div>
					</div>
				)
			);
		// --- Currently we don't need functional component of TinyTextEditor ---

		// case 'inside-form-editor':
		// 	return (
		// 		item.isVisible && (
		// 			<div key={`${item.name}-${index}`} style={{ position: 'relative' }}>
		// 				<Field
		// 					{...item}
		// 					key={`${item.name}-${index}`}
		// 					disabled={submitting || item.disabled}
		// 					name={item.name}
		// 					id={item.name}
		// 					requiredField={item.requiredField}
		// 					label={item.label}
		// 					labelColumn={item.labelColumn}
		// 					inputColumn={item.inputColumn}
		// 					fieldInfo={item.fieldInfo}
		// 					disabledAddingImage={item.disabledAddingImage}
		// 					disabledAddingMedia={item.disabledAddingMedia}
		// 					component={TinyTextEditorField}
		// 				/>

		// 				{item.disabled && (
		// 					//config readonly does not work in TinyTextEditor
		// 					//added div to cover editor and prevent user from editing content
		// 					<div
		// 						style={{
		// 							position: 'absolute',
		// 							top: 0,
		// 							left: 0,
		// 							height: '100%',
		// 							width: '100%',
		// 							zIndex: 999
		// 						}}
		// 					></div>
		// 				)}
		// 				<div className="hr-line-dashed">&nbsp;</div>
		// 			</div>
		// 		)
		// 	);

		case 'date':
			return (
				item.isVisible && (
					<div key={`${item.name}-${index}`}>
						<Field
							key={`${item.name}-${index}`}
							disabled={submitting}
							name={item.name}
							type="number"
							placeholder={item.placeholder}
							label={item.label}
							id={item.name}
							requiredField={item.requiredField}
							showTime={item.showTime}
							component={DatePickerLabel}
							labelColumn={item.labelColumn}
							inputColumn={item.inputColumn}
							style={item.style}
						/>
						<div className="hr-line-dashed">&nbsp;</div>
					</div>
				)
			);

		case 'time':
			return (
				item.isVisible && (
					<div key={`${item.name}-${index}`}>
						<Field
							key={`${item.name}-${index}`}
							disabled={submitting}
							name={item.name}
							type="time"
							placeholder={item.placeholder}
							label={item.label}
							id={item.name}
							requiredField={item.requiredField}
							component={TimePickerLabel}
							labelColumn={item.labelColumn}
							inputColumn={item.inputColumn}
							style={item.style}
						/>
						<div className="hr-line-dashed">&nbsp;</div>
					</div>
				)
			);

		case 'range':
			return (
				item.isVisible && (
					<div key={`${item.name}-${index}`}>
						<Field
							key={`${item.name}-${index}`}
							disabled={submitting}
							name={item.name}
							type="number"
							placeholder={item.placeholder}
							label={item.label}
							id={item.name}
							requiredField={item.requiredField}
							showTime={item.showTime}
							component={RangePickerLabel}
							labelColumn={item.labelColumn}
							inputColumn={item.inputColumn}
							style={item.style}
							rangeFormat={item.rangeFormat}
						/>
						<div className="hr-line-dashed">&nbsp;</div>
					</div>
				)
			);

		case 'number':
			return (
				item.isVisible && (
					<div key={`${item.name}-${index}`}>
						<Field
							key={`${item.name}-${index}`}
							disabled={submitting || item.disabled}
							name={item.name}
							type="number"
							placeholder={item.placeholder}
							label={item.label}
							id={item.name}
							// We have only zero or positive value by default
							min={item.min ?? 0}
							requiredField={item.requiredField}
							component={InputLabel}
							labelColumn={item.labelColumn}
							inputColumn={item.inputColumn}
							fieldInfo={item.fieldInfo}
						/>
						<div className="hr-line-dashed">&nbsp;</div>
					</div>
				)
			);

		// rember to provide array attached to the same key as item.name
		// for example item.name = title, then selectResources = {title: []}
		case 'multi-select':
			return (
				item.isVisible && (
					<div key={`${item.name}-${index}`}>
						<Field
							key={`${item.name}-${index}`}
							disabled={submitting || item.disabled}
							name={item.name}
							type="multi-select"
							mode={item.mode}
							placeholder={item.placeholder}
							label={item.label}
							id={item.name}
							requiredField={item.requiredField}
							component={MultiSelectLabel}
							onSearch={onSearch}
							showSearch={item.showSearch}
							fieldInfo={item.fieldInfo}
							labelColumn={item.labelColumn}
							inputColumn={item.inputColumn}
							dropdownStyle={item.dropdownStyle}
							onChangeCallback={item.onChangeCallback}
						>
							{selectResources[item.name].map((item, index) => (
								<Select.Option key={index} value={item}>
									{item}
								</Select.Option>
							))}
						</Field>
						<div className="hr-line-dashed">&nbsp;</div>
					</div>
				)
			);

		// rember to provide array attached to the same key as item.name
		// for example item.name = title, then selectResources = {title: []}
		case 'select':
			return (
				item.isVisible && (
					<div key={`${item.name}-${index}`}>
						<Field
							key={`${item.name}-${index}`}
							disabled={submitting || item.disabled}
							name={item.name}
							type="select"
							mode={item.mode}
							placeholder={item.placeholder}
							label={item.label}
							id={item.name}
							requiredField={item.requiredField}
							fieldInfo={item.fieldInfo}
							component={SelectLabel}
							labelColumn={item.labelColumn}
							inputColumn={item.inputColumn}
							allowClear={item.allowClear}
							onChangeCallback={item.onChangeCallback}
						>
							{selectResources[item.name].map((item, index) => (
								<Select.Option key={index} value={item.value}>
									{item.name}
								</Select.Option>
							))}
						</Field>
						<div className="hr-line-dashed">&nbsp;</div>
					</div>
				)
			);

		case 'checkbox':
			return (
				item.isVisible && (
					<div key={`${item.name}-${index}`}>
						<Field
							key={`${item.name}-${index}`}
							disabled={submitting || item.disabled}
							name={item.name}
							type="checkbox"
							placeholder={item.placeholder}
							label={item.label}
							id={item.name}
							requiredField={item.requiredField}
							fieldInfo={item.fieldInfo}
							component={CheckboxLabel}
							labelColumn={item.labelColumn}
							inputColumn={item.inputColumn}
							{...item}
						/>
						<div className="hr-line-dashed">&nbsp;</div>
					</div>
				)
			);

		case 'file':
			return (
				item.isVisible && (
					<div key={`${item.name}-${index}`}>
						<Field
							key={`${item.name}-${index}`}
							disabled={submitting}
							name={item.name}
							type="file"
							placeholder={item.placeholder}
							label={item.label}
							id={item.name}
							requiredField={item.requiredField}
							component={InputFileLabel}
							labelColumn={item.labelColumn}
							inputColumn={item.inputColumn}
						/>
						<div className="hr-line-dashed">&nbsp;</div>
					</div>
				)
			);

		case 'text-w-button-async':
			return (
				item.isVisible && (
					<div key={`${item.name}-${index}`}>
						<Field
							key={`${item.name}-${index}`}
							disabled={submitting || item.disabled}
							name={item.name}
							type={item.type}
							placeholder={item.placeholder}
							label={item.label}
							id={item.name}
							requiredField={item.requiredField}
							buttonText={item.buttonText}
							url={item.url}
							component={InputLabelButtonAsync}
						/>
						<div className="hr-line-dashed">&nbsp;</div>
					</div>
				)
			);

		case 'text-w-button-sync':
			return (
				item.isVisible && (
					<div key={`${item.name}-${index}`}>
						<Field
							key={`${item.name}-${index}`}
							disabled={submitting || item.disabled}
							name={item.name}
							type={item.type}
							placeholder={item.placeholder}
							label={item.label}
							id={item.name}
							requiredField={item.requiredField}
							buttonText={item.buttonText}
							component={InputLabelButtonSync}
							relatedField={item.relatedField}
						/>
						<div className="hr-line-dashed">&nbsp;</div>
					</div>
				)
			);

		// multi-select to which you can provide select resources as array of objects with name and value keyts {name: foo, value: bar}
		case 'custom-names':
			return (
				item.isVisible && (
					<div key={`${item.name}-${index}`}>
						<Field
							key={`${item.name}-${index}`}
							disabled={submitting || item.disabled}
							name={item.name}
							type="multi-select"
							mode={item.mode}
							placeholder={item.placeholder}
							label={item.label}
							id={item.name}
							requiredField={item.requiredField}
							component={MultiSelectLabel}
							onSearch={onSearch}
							showSearch={item.showSearch}
							fieldInfo={item.fieldInfo}
							labelColumn={item.labelColumn}
							inputColumn={item.inputColumn}
							filterOption={item.filterOption || true}
							optionFilterProp={item.optionFilterProp || 'value'}
							onChangeCallback={item.onChangeCallback}
							allowClear={item.allowClear || false}
						>
							{selectResources[item.name].map((item, index) => (
								<Select.Option key={index} value={item.value}>
									{item.name}
								</Select.Option>
							))}
						</Field>
						<div className="hr-line-dashed">&nbsp;</div>
					</div>
				)
			);

		// this option is barely used (provider_id field in series and seasons form)
		// however it is quite generic and can be used elsewhere
		case 'multi-select-on-change':
			return (
				item.isVisible && (
					<div key={`${item.name}-${index}`}>
						<Field
							key={`${item.name}-${index}`}
							disabled={submitting || item.disabled}
							name={item.name}
							type="multi-select"
							mode={item.mode}
							placeholder={item.placeholder}
							label={item.label}
							id={item.name}
							requiredField={item.requiredField}
							component={MultiselectLabelCustomChange}
							onSearch={onSearch}
							onChangeAction={item.onChangeAction}
							showSearch={item.showSearch}
							fieldInfo={item.fieldInfo}
							labelColumn={item.labelColumn}
							inputColumn={item.inputColumn}
							currentValues={item.currentValues}
							clearFieldName={item.clearFieldName}
						>
							{selectResources[item.name].map((item, index) => (
								<Select.Option key={index} value={item}>
									{item}
								</Select.Option>
							))}
						</Field>
						<div className="hr-line-dashed">&nbsp;</div>
					</div>
				)
			);

		// --------------------- CUSTOM FIELDS -----------------------
		// USED WITH SPECIFIC FORMS IN MIND

		// custom providers field for season and series forms
		case 'provider-field-multi-select':
			return (
				item.isVisible && (
					<div key={`${item.name}-${index}`}>
						<Field
							key={`${item.name}-${index}`}
							disabled={submitting || item.disabled}
							name={item.name}
							type="multi-select"
							mode={item.mode}
							placeholder={item.placeholder}
							label={item.label}
							id={item.name}
							requiredField={item.requiredField}
							component={ProviderFieldMultiSelectLabel}
							onSearch={onSearch}
							onChangeAction={item.onChangeAction}
							showSearch={item.showSearch}
							labelColumn={item.labelColumn}
							inputColumn={item.inputColumn}
						/>
						<div className="hr-line-dashed">&nbsp;</div>
					</div>
				)
			);

		case 'multi-select-no-resources':
			return (
				item.isVisible && (
					<div key={`${item.name}-${index}`}>
						<Field
							key={`${item.name}-${index}`}
							disabled={submitting || item.disabled}
							name={item.name}
							type="multi-select"
							mode={item.mode}
							placeholder={item.placeholder}
							label={item.label}
							id={item.name}
							requiredField={item.requiredField}
							component={MultiSelectLabel}
							onSearch={onSearch}
							showSearch={item.showSearch}
							fieldInfo={item.fieldInfo}
							labelColumn={item.labelColumn}
							inputColumn={item.inputColumn}
							dropdownStyle={{ display: 'none' }}
						/>
						<div className="hr-line-dashed">&nbsp;</div>
					</div>
				)
			);

		// inverted label with input - for agreements in products
		case 'checkbox-inverted':
			return (
				item.isVisible && (
					<div key={`${item.name}-${index}`}>
						<Field
							key={`${item.name}-${index}`}
							disabled={submitting || item.disabled}
							name={item.name}
							type="checkbox"
							placeholder={item.placeholder}
							label={item.label}
							id={item.name}
							requiredField={item.requiredField}
							fieldInfo={item.fieldInfo}
							component={CheckboxLabelInverted}
							labelColumn={item.labelColumn}
							inputColumn={item.inputColumn}
						/>
						<div className="hr-line-dashed">&nbsp;</div>
					</div>
				)
			);

		default:
			return (
				item.isVisible && (
					<div key={`${item.name}-${index}`}>
						<Field
							key={`${item.name}-${index}`}
							disabled={submitting || item.disabled}
							name={item.name}
							type={item.type}
							placeholder={item.placeholder}
							label={item.label}
							id={item.name}
							requiredField={item.requiredField}
							component={InputLabel}
							labelColumn={item.labelColumn}
							inputColumn={item.inputColumn}
							fieldInfo={item.fieldInfo}
							parse={(value) => value}
						/>
						<div className="hr-line-dashed">&nbsp;</div>
					</div>
				)
			);
	}
};
