import React from 'react';
import { Link } from 'react-router-dom';

// Import translation
import i18n from 'i18next';

// Import convert
import { convertPrice, convertPriceModel } from 'components/helpers/convert';
import { mapSingleToPlural } from 'components/helpers/name_mapping';

export const createColumns = ({ subtype }) => {
	return [
		{
			Header: i18n.t('payments:table_columns.title'),
			accessor: 'title',
			minWidth: 200,
			Cell: ({
				row: {
					_original: { product_uuid, product_title, product_type }
				}
			}) => {
				const path =
					product_type === 'vod'
						? `/panel/vod/${subtype}/edit/${product_uuid}/metadata`
						: `/panel/${mapSingleToPlural(
								product_type
						  )}/edit/${product_uuid}/metadata`;

				return <Link to={path}>{product_title}</Link>;
			}
		},
		{
			Header: i18n.t('payments:table_columns.products_group_name'),
			accessor: 'products_group_name',
			minWidth: 200
		},
		{
			Header: i18n.t('payments:table_columns.price'),
			accessor: 'price',
			minWidth: 150,
			Cell: ({
				row: {
					_original: { price }
				}
			}) => <span>{convertPrice(price)}</span>
		},
		{
			Header: i18n.t('prices:table_columns.payment_method'),
			accessor: 'payment_method',
			minWidth: 200,
			Cell: ({
				row: {
					_original: { payment_method }
				}
			}) => <span>{i18n.t(`prices:payments.${payment_method}`)}</span>
		},
		{
			Header: i18n.t('payments:table_columns.period'),
			accessor: 'period',
			minWidth: 150,
			Cell: ({
				row: {
					_original: { period }
				}
			}) => (
				<span>
					{parseInt(period) === -1
						? i18n.t('prices:recurring_payment')
						: period}
				</span>
			)
		},
		{
			Header: i18n.t('payments:table_columns.model'),
			accessor: 'since_first_access',
			minWidth: 200,
			Cell: ({
				row: {
					_original: { since_first_access }
				}
			}) => <span>{convertPriceModel(since_first_access)}</span>
		},
		{
			Header: i18n.t('payments:table_columns.views_limit'),
			accessor: 'views_limit',
			minWidth: 150,
			Cell: ({
				row: {
					_original: { views_limit }
				}
			}) => <span>{views_limit ?? i18n.t('common:none')}</span>
		},
		{
			Header: i18n.t('payments:table_columns.created_at'),
			accessor: 'created_at',
			width: 200
		},
		{
			Header: i18n.t('payments:table_columns.status'),
			accessor: 'status',
			minWidth: 150,
			Cell: ({
				row: {
					_original: { status }
				}
			}) => <span>{i18n.t(`payments:status.${status}`)}</span>
		},
		{
			Header: i18n.t('payments:table_columns.user_login'),
			accessor: 'user_login',
			minWidth: 200,
			Cell: ({
				row: {
					_original: { user_id, user_login }
				}
			}) => (
				<Link to={`/panel/subscribers/edit/${user_id}/metadata`}>
					{user_login}
				</Link>
			)
		}
	];
};
