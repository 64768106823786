import services from 'services/services';

// Import translation
import i18n from 'i18next';

// Import helpers
import { convertErrors, translateError } from 'components/helpers/error';
import { capitalizeText } from 'components/helpers/convert';
import { convertResourcesDate } from './helpers';

// Import utilities
import {
	createFormNotification,
	notificationHandler
} from 'components/utilities/notifications/index';

export const submitForm = async ({
	isEdit = true,
	itemID,
	resources,
	history,
	api,
	notificationName = null,
	scrollTop = true,
	redirect = true, // sets request url
	redirectToPath = false, // redirect to specified path
	redirectToEdit = true, // redirect to edit form after creating
	redirectPath = '',
	apiSlug = '',
	callback = null, //callback function e.g. redux action
	editBasePath = ''
}) => {
	try {
		// Check if it is edit, return create or id to update
		const path = !isEdit ? itemID : 'create';
		// Check method - create or update. Updating software via software form update requires using post method
		const setMethod = () => {
			let method = !isEdit ? services.put : services.post;
			// some edit / update requests require post method instead of put
			if (api === 'device/software') method = services.post;
			if (api === 'promotions' && apiSlug === 'users') method = services.post;
			if (api === 'promotions' && apiSlug === 'products/out/algorithm/generate')
				method = services.post;
			if (api === 'promotions' && apiSlug === 'products/in/algorithm/generate')
				method = services.post;
			if (api === 'channel/systemFields') method = services.post;
			if (api === 'channel/platformFields') method = services.post;
			if (api === 'channel/programs/systemFields') method = services.post;
			if (api === 'subscriber/notifications') method = services.post;
			if (api === 'vod/massDelete') method = services.delete;
			if (api === 'timetables/availability' && !isEdit) method = services.put;
			if (api === 'timetables/visibility' && !isEdit) method = services.put;
			if (api === 'geoblocking/product') method = services.post;
			if (api === 'statistics/products') method = services.post;
			if (api === 'affiliate' && apiSlug === 'codes') method = services.post;
			return method;
		};

		const covertedResources = convertResourcesDate(resources);

		// Sometimes request url has slug
		const checkSlug = !isEdit ? apiSlug : '';
		// Sent request with resources
		const response = await setMethod()(
			`/${api}${redirect ? `/${path}/${checkSlug}` : ''}`,
			covertedResources
		);

		// if it was create, redirect to the edit
		if (redirectToEdit && isEdit && !redirectToPath) {
			const currentUrl = history.location.pathname;
			const basePath = editBasePath || currentUrl.split('/create')[0];
			const elemId =
				response.data.id || response.data.uuid || response.data.data.id;
			const newUrl = `${basePath}/edit/${elemId}/metadata`;
			history.push(newUrl);
		}

		// call callback function
		if (callback) {
			callback();
		}

		// Go back to the main panel
		// creating and editing does not redirect
		/* if you whant have redirection create an if statement
		   and change redirectToPath value to true */
		redirectToPath && history.push(`/panel/${redirectPath}`);
		// Create notification
		createFormNotification(isEdit, capitalizeText(notificationName || api));
		// Scroll to top
		scrollTop &&
			window.scroll({
				top: 0,
				behavior: 'smooth'
			});
	} catch (error) {
		createFormNotification(
			isEdit,
			capitalizeText(notificationName || api),
			'error'
		);

		return handleErrors(error);
	}
};

const handleErrors = (error) => {
	const {
		response: {
			status,
			data: { message }
		}
	} = error;

	if (status === 422) {
		const errors = convertErrors(error.response.data.validator.errors);

		// Throw new errors
		return { ...errors };
	}
	if (status === 406) {
		notificationHandler(
			i18n.t('common:error'),
			translateError(message),
			'error',
			8
		);
		// Throw new errors
		return null;
	}
	if (status !== 401) {
		// Convert Errors
		const errors = convertErrors(error.response.data.validator.errors);
		// CreateNotification
		Object.entries(errors).forEach(([key, value]) => {
			notificationHandler(capitalizeText(key), value, 'error', 8);
		});
		// Throw new errors
		return { ...errors };
	}
};
