// Import translation
import i18n from 'i18next';

// convert location type to proper API type
export const convertLocationType = (location, isEpg = false) => {
	if (isEpg) {
		return 'channel/programs';
	} else {
		switch (location) {
			case 'provisioning':
			case 'main':
			case 'packets':
				return 'packet';

			case 'channels':
				return 'channel';

			case 'sections':
				return 'section';

			case 'sections_groups':
				return 'section/group';

			case 'articles':
				return 'products/articles';

			case 'events_live':
				return 'products/liveevents';

			case 'audio':
				return 'vod';

			default:
				return location;
		}
	}
};

// get name from response data (response data is different for different product types)
export const getName = (data, type) => {
	switch (type) {
		case 'vod':
			return data.metadata.title;

		case 'section': //section needs to show both name and label since name can be an empty string
			const name = data.name;
			const label = data.label;
			const sectionName = `${i18n.t(
				'sections:bread_crumbs.section_name'
			)}: ${name} | ${i18n.t('sections:bread_crumbs.section_label')}: ${label}`;
			return sectionName;

		case 'section/group':
			return data.name;

		case 'channel':
		case 'packet':
		case 'channel/programs':
		case 'products/articles':
		case 'products/liveevents':
			return data.title;

		default:
			return '';
	}
};

// checks if product is eligible for displaing its name
export const checkProperType = (type) => {
	switch (type) {
		case 'vod':
		case 'audio':
		case 'packet':
		case 'channel':
		case 'section':
		case 'section/group':
		case 'channel/programs':
		case 'products/articles':
		case 'products/liveevents':
			return true;

		default:
			return false;
	}
};

// checks if product has eligible subtype for displaing web link
export const checkProperSubtype = (subtype) => {
	let isProperSubtype = false;
	switch (subtype) {
		case 'vod':
			return (isProperSubtype = true);
		case 'series':
			return (isProperSubtype = true);
		default:
			return isProperSubtype;
	}
};

// convert dashboard subtype to web subtype (movies instead of vod)
// may need additional choices in future
export const convertToWebSubtype = (subtype) => {
	switch (subtype) {
		case 'vod':
			return 'movies';

		default:
			return subtype;
	}
};

// check if we are in epg location (needed for rendering program name in epg tabs)
export const checkForEpg = (pathname) => {
	const pathnameArray = pathname.split('/');
	const isEpg = pathnameArray[4] === 'epg';
	return isEpg;
};

export const translateSubtypes = (subtype) => {
	switch (subtype) {
		case 'vod':
			return 'vod';
		case 'series':
			return 'serial';

		default:
			break;
	}
};
