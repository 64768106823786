import services from 'services/services';
import * as types from '../types';

// Import helpers
import { convertArrayToValuePairsByKeyNames } from 'components/helpers/convert';

// ******************** VIDEO FORMATS ********************
export const dictionaryVideoFormats = () => async (dispatch) => {
	const { data } = await services.get(`dictionary/getVideoFormats`);

	dispatch({
		type: types.DICTIONARY_VIDEO_FORMATS,
		payload: data
	});
};

// ******************** PRODUCT TYPES ********************
export const dictionaryProductTypes = () => async (dispatch) => {
	const { data } = await services.get(`dictionary/getProductTypes`);

	dispatch({
		type: types.DICTIONARY_PRODUCT_TYPES,
		payload: data
	});
};

// ******************** VIDEO TYPES ********************
export const dictionaryVideoTypes = () => async (dispatch) => {
	const { data } = await services.get(`dictionary/getVideoTypes`);

	dispatch({
		type: types.DICTIONARY_VIDEO_TYPES,
		payload: data
	});
};

// ******************** ASSETS COLLECTION TYPES ********************
export const dictionaryAssetsCollectionTypes = () => async (dispatch) => {
	const { data } = await services.get(`dictionary/getAssetsCollections`);

	dispatch({
		type: types.DICTIONARY_ASSETS_COLLECTION_TYPES,
		payload: data
	});
};

// ******************** COUNTER TYPES ********************
export const dictionaryCounterTypes = () => async (dispatch) => {
	const { data } = await services.get(`dictionary/getCounterTypes`);

	dispatch({
		type: types.DICTIONARY_COUNTER_TYPES,
		payload: data
	});
};

// ******************** IP RANGE TYPES ********************
export const dictionaryIpRangeTypes = () => async (dispatch) => {
	const { data } = await services.get(`dictionary/getIpRangeTypes`);

	dispatch({
		type: types.DICTIONARY_IP_RANGE_TYPES,
		payload: data
	});
};

// ******************** PACKETS TYPES ********************
export const dictionaryPacketsTypes = () => async (dispatch) => {
	const { data } = await services.get(`dictionary/getPacketTypes`);

	dispatch({
		type: types.DICTIONARY_PACKETS_TYPES,
		payload: data
	});
};

// ******************** PARENTAL CONTROL RATING ********************
export const dictionaryParentalControlRating = () => async (dispatch) => {
	const { data } = await services.get(`dictionary/getRatings`);

	dispatch({
		type: types.DICTIONARY_PARTENTAL_CONTROL_RATING,
		payload: data
	});
};

// ******************** PAYMENT METHODS ********************
export const dictionaryPaymentMethods = () => async (dispatch) => {
	const { data } = await services.get(`dictionary/getPaymentMethods`);

	dispatch({
		type: types.DICTIONARY_PAYMENT_METHODS,
		payload: data
	});
};

// ******************** PLAY MODES ********************
export const dictionaryPlayModes = () => async (dispatch) => {
	const { data } = await services.get(`dictionary/getPlayModes`);

	dispatch({
		type: types.DICTIONARY_PLAY_MODES,
		payload: data
	});
};

// ******************** REMINDER TYPES ********************
export const dictionaryReminderTypes = () => async (dispatch) => {
	const { data } = await services.get(`dictionary/getReminderTypes`);

	dispatch({
		type: types.DICTIONARY_REMINDER_TYPES,
		payload: data
	});
};

// ******************** SECTION TYPES ********************
export const dictionarySectionTypes = () => async (dispatch) => {
	const { data } = await services.get(`dictionary/getSectionTypes`);

	dispatch({
		type: types.DICTIONARY_SECTION_TYPES,
		payload: data
	});
};

// ******************** AGREEMENTS TYPES ********************
export const dictionaryAgreementsTypes = () => async (dispatch) => {
	const { data } = await services.get(`dictionary/getAgreementTypes`);

	dispatch({
		type: types.DICTIONARY_AGREEMENTS_TYPES,
		payload: data
	});
};

// ******************** PLATFORMS TYPES ********************
export const dictionaryPlatformsTypes = () => async (dispatch) => {
	const { data } = await services.get(`dictionary/getPlatforms`);

	dispatch({
		type: types.DICTIONARY_PLATFORMS_TYPES,
		payload: data
	});
};

// ******************** PAYMENT MODELS ********************
export const dictionaryPaymentModels = () => async (dispatch) => {
	const { data } = await services.get(`dictionary/getPaymentModels`);

	dispatch({
		type: types.DICTIONARY_PAYMENT_MODELS,
		payload: data
	});
};

// ******************** CAST TYPES ********************
export const dictionaryCastTypes = () => async (dispatch) => {
	const { data } = await services.get(`dictionary/getCastTypes`);

	dispatch({
		type: types.DICTIONARY_CAST_TYPES,
		payload: data
	});
};

// ******************** VOD PROVIDERS ********************
export const dictionaryVODProviders = () => async (dispatch) => {
	const { data } = await services.get(`dictionary/getVodProviders`);

	dispatch({
		type: types.DICTIONARY_VOD_PROVIDERS,
		payload: data
	});
};

// ******************** VOD SUBSTATUS ********************
export const dictionaryVODSubstatus = () => async (dispatch) => {
	const { data } = await services.get(`dictionary/getVodSubStatus`);

	dispatch({
		type: types.DICTIONARY_VOD_SUBSTATUS,
		payload: data
	});
};

// ******************** SUBSCRIBER SOURCE ********************
export const dictionarySubscriberSource = () => async (dispatch) => {
	const { data } = await services.get(`dictionary/getSubscriberSources`);

	dispatch({
		type: types.DICTIONARY_SUBSCRIBER_SOURCE,
		payload: data
	});
};

// ******************** INSTITUTIONS SOURCE ********************
export const fetchInstitutionsList = () => async (dispatch) => {
	const {
		data: { data }
	} = await services.get('/productsgroup/list');

	dispatch({
		type: types.DICTIONARY_INSTITUTIONS_LIST,
		payload: data
	});
};

// ******************** GEOBLOCK GROUPS SOURCE ********************
export const dictionaryGeoblockGroups = () => async (dispatch) => {
	const {
		data: { data }
	} = await services.get('/geoblocking/list');

	const geoblockGroups = data.map(({ name, id, countries }) => {
		const countriesCodes = countries.map(({ code }) => code);

		return {
			name,
			id,
			value: id,
			countries: countriesCodes
		};
	});

	dispatch({
		type: types.DICTIONARY_GEOBLOCK_GROUPS_LIST,
		payload: geoblockGroups
	});
};

// ******************** COUNTRIES CODES SOURCE ********************
export const dictionaryCountriesWithCodes = () => async (dispatch) => {
	const { data } = await services.get('/dictionary/getCountriesWithCodes');

	dispatch({
		type: types.DICTIONARY_COUNTRIES_WITH_CODES,
		payload: data
	});
};

// ******************** MODEL TYPES ********************
export const dictionaryModelTypes = () => async (dispatch) => {
	const { data } = await services.get(`dictionary/getModelTypes`);

	dispatch({
		type: types.DICTIONARY_MODEL_TYPES,
		payload: Object.entries(data)
	});
};

// ******************** PERIOD UNIT ********************
export const dictionaryPeriodUnit = () => async (dispatch) => {
	const { data } = await services.get(`dictionary/getPeriodUnit`);

	dispatch({
		type: types.DICTIONARY_PERIOD_UNIT,
		payload: Object.entries(data)
	});
};

// ******************** DOCUMENTS LIST ********************
export const fetchDocumentsList = () => async (dispatch) => {
	const {
		data: { data }
	} = await services.get('/documents/list');

	dispatch({
		type: types.DICTIONARY_DOCUMENTS_LIST,
		payload: data
	});
};

// ******************** SECTION LIST ********************
export const fetchSectionsList = () => async (dispatch) => {
	const {
		data: { data }
	} = await services.get('/section/list');

	dispatch({
		type: types.DICTIONARY_SECTION_LIST,
		payload: data
	});
};

// ******************** PRODUCTS GENRES LIST ********************
export const fetchProductsGenresList = () => async (dispatch) => {
	const {
		data: { data }
	} = await services.get('/products/genres/list');

	dispatch({
		type: types.DICTIONARY_PRODUCTS_GENRES_LIST,
		payload: data
	});
};

// ******************** SECTION GROUP LIST ********************
export const fetchSectionGroupList = () => async (dispatch) => {
	const {
		data: { data }
	} = await services.get('/section/group/list');

	dispatch({
		type: types.DICTIONARY_SECTION_GROUP_LIST,
		payload: data
	});
};

// ******************** TAGS LIST  ********************
export const fetchTagsList = () => async (dispatch) => {
	const {
		data: { data }
	} = await services.get('/tags/list');

	dispatch({
		type: types.DICTIONARY_TAGS_LIST,
		payload: data
	});
};

// ******************** PROMOTIONS SOURCE ********************
export const fetchPromotionsList = () => async (dispatch) => {
	// Only active promotions with specification: products_list
	const url =
		'/promotions/list?columns[3][search][value]=1&specification=products_list';

	const {
		data: { data }
	} = await services.get(url);

	dispatch({
		type: types.DICTIONARY_PROMOTIONS_LIST,
		payload: convertArrayToValuePairsByKeyNames(data, 'name', 'id')
	});
};
