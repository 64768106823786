// Import translation
import i18n from 'i18next';

// Localstorage
export const LOCALSTORAGE_USER = 'blueonline';

// Ingester stat types (assets | encoding)
export const ASSETS = 'assets';
export const ENCODING = 'encoding';

// list of select elementd to filter VOD table with
export const VOD_SUBTYPES = (isAudio) => {
	const subtypeSlug = isAudio ? 'audio' : 'vod';

	return [
		{ name: i18n.t('vod:select:subtypes.all'), value: 'all' },
		{ name: i18n.t(`vod:select:subtypes.${subtypeSlug}`), value: 'vod' },
		{ name: i18n.t('vod:select:subtypes.series'), value: 'series' },
		{ name: i18n.t('vod:select:subtypes.season'), value: 'season' },
		{ name: i18n.t('vod:select:subtypes.episode'), value: 'episode' }
	];
};

export const VOD_STATUS = () => [
	{ name: i18n.t('vod:select:status.all'), value: 'all' },
	{ name: i18n.t('vod:select:status.new'), value: 'new' },
	{ name: i18n.t('vod:select:status.encoded'), value: 'encoded' },
	{ name: i18n.t('vod:select:status.complete'), value: 'complete' },
	{ name: i18n.t('vod:select:status.active'), value: 'active' },
	{ name: i18n.t('vod:select:status.expired'), value: 'expired' },
	{ name: i18n.t('vod:select:status.deleted'), value: 'deleted' }
];

// provisioning status
export const PROVISIONING_STATUS = [
	'new',
	'encoded',
	'complete',
	'active',
	'expired'
];

// attach episodes modal list length
export const EPISODES_MODAL_SEARCH_LENGTH = 100;

// add products (Product Select) vod list length
export const PRODUCT_SELECT_VOD_LIST_LENGTH = 100;

// link regex to url validaion
export const LINK_REGEX = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/;

// Event types options for select type field in events_live
export const select_event_live_types = [
	{
		name: 'EVENT LIVE',
		value: 'live_event'
	}
];

// List of table types for which export to csv option is disabled
// Names are based on url types
export const disabledCSVExportList = [
	'tracking',
	'trackings',
	'affiliate_partners'
];

// Products limit in Section Preview
export const SECTION_PREVIEW_LIMIT = 100;

// Products limit in Products Preview
export const PROMOTIONS_PRODUCTS_PREVIEW_LIMIT = 100;

// Products limit in Packets Products List
export const PACKETS_PRODUCTS_LIST_LIMIT = 100;

// Promotion Products types
export const PROMOTION_PRODUCTS_IN = 'in';
export const PROMOTION_PRODUCTS_OUT = 'out';
// materials table limits
export const CHANNELS_MATERIALS_LIMIT = 4;
export const VOD_MATERIALS_LIMIT = 3;
export const ARTICLE_MATERIALS_LIMIT = 3;
export const EVENTS_LIVE_MATERIALS_LIMIT = 4;

// language local storage value
export const LOCAL_STORAGE_LANGUAGE = 'language';

// status types (mainly used for tracking request status in Redux)
export const STATUS_TYPES = {
	idle: 'idle',
	loading: 'loading',
	success: 'success',
	error: 'error',
	pending: 'pending'
};

// ------------ SYSTEMS ------------
// system types
export const SYSTEM_TYPES = {
	ott: 'ott',
	tvonline: 'tvonline',
	obox: 'obox'
};
// system types custom names
export const SYSTEM_CUSTOM_NAMES = {
	[SYSTEM_TYPES.ott]: 'OTT',
	[SYSTEM_TYPES.tvonline]: 'DTV',
	[SYSTEM_TYPES.obox]: 'OBOX'
};

// array of systems defined in env file
export const SYSTEM_TYPES_FROM_ENV =
	process.env.REACT_APP_SYSTEMS.split(',') ?? [];

// system visible types
export const SYSTEM_VISIBLE_TYPES = {
	ott: 'ott',
	tvonline: 'tvonline'
};
// ----------------------------------

// ------------ PLATFORM ------------
// platform types
export const PLATFORM_TYPES = {
	all: 'all',
	platformPrimary: 'platform_primary',
	platformSecondary: 'platform_secondary'
};
// ----------------------------------

// blackout variables for generating EPG table
export const BLACKOUT_TYPES = ['streaming', 'catchup', 'npvr'];
export const BLACKOUT_PLATFORMS = [
	{ name: 'BOX', value: 'primary' },
	{ name: 'Secondary Screens', value: 'secondary' }
];

// ------------ SETTINGS ------------
// block settings sections with these names
export const SETTINGS_TO_BLOCK_IN_OBOX = ['ESALES', 'NETFLIX'];
// block settings fields with these names
export const SETTINGS_FIELDS_TO_BLOCK_IN_OBOX = [
	'PLAYER_MULTICAST ESALES INTERNET_DEVICE_MODELS',
	'MULTICAST ESALES INTERNET_DEVICE_MODELS'
];
// ----------------------------------

// ------------ PAYMENT ------------
export const PAYMENT_TYPES = {
	BLUEMEDIA: 'bluemedia',
	SUBSCRIPTION_BLUEMEDIA: 'subscription_bluemedia',
	DCB: 'dcb',
	SUBSCRIPTION_DCB: 'subscription_dcb'
};
// ----------------------------------

// ------------ SUBSCRIBERS ------------
export const SUBSCRIBER_SOURCE_TYPES = {
	PURE_OTT: 'pureott',
	FIREBASE: 'firebase'
};
// ----------------------------------

// ------------ DURATION ------------
export const DURATION_CONVERT_TYPES = {
	HMS_TO_SECONDS: 'HMS_TO_SECONDS',
	SECONDS_TO_HMS: 'SECONDS_TO_HMS'
};

// ------------ PRODUCT TYPES ------------
export const PRODUCT_TYPES = {
	VOD: 'vod',
	CHANNEL: 'channel',
	ARTICLE: 'article',
	EVENT_LIVE: 'live_event',
	PACKET: 'packet'
};

export const GLOBAL_PRODUCT_TYPES = {
	VOD: 'vod',
	AUDIO: 'audio',
	CHANNEL: 'channel',
	ARTICLE: 'article',
	EVENT_LIVE: 'event_live',
	PACKET: 'packet',
	SERIES: 'series',
	PASS: 'pass',
	BANNER: 'banner',
	LIVE_EVENT: 'live_event'
};

// ------------ GLOBAL LIMIT PRODUCT TYPES ------------
export const GLOBAL_LIMIT_PRODUCT_TYPES = {
	VOD: 'vod',
	SERIES: 'series',
	ARTICLE: 'article',
	EPISODE: 'episode',
	SEASON: 'season',
	LIVE_EVENT: 'live_event'
};

// ------------ PACKET TYPES ------------
export const PACKET_TYPES = {
	COLLECTIONS: 'collections'
};

// ------------ UPLOAD MATERIAL ------------
export const MATERIALS_COLLECTION_TYPES = {
	VIDEO: 'video',
	AUDIO: 'audio'
};
// ----------------------------------

// ------------ CORE PATHS ------------
export const CORE_PATHS = {
	CHANNELS: 'channels',
	VOD: 'vod',
	AUDIO: 'audio',
	ARTICLES: 'articles',
	EVENTS_LIVE: 'events_live',
	PASSES: 'passes'
};
// ----------------------------------

export const REQUIRED_MARK = '*';

// ------------ DATE FORMATS ------------
export const DATE_FORMATS = {
	LOCALE_DATE_FORMAT: 'YYYY-MM-DD HH:mm:ss',
	UTC_DATE_FORMAT: 'YYYY-MM-DD HH:mm:ss'
};

// ------------ DATE PROPERTIES ------------
const dateProperties = {
	CREATED_AT: 'created_at',
	UPDATED_AT: 'updated_at',
	LAST_LOGIN_DATE: 'last_login_date',
	LAST_LOGIN: 'last_login',
	SINCE: 'since',
	TILL: 'till'
};

export const DATE_NAMES = Object.values(dateProperties);

// ------------ AFFILIATE PARTNERS ------------
export const AFFILIATE_PARTNER_CODE_LENGTH = 10;

export const TRANSCODING_STATUS_TYPES = {
	FAILED: 'failed',
	REMOVED: 'removed',
	SUCCEEDED: 'succeeded',
	PROCESSING: 'processing',
	CREATED: 'created'
};
