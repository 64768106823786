// add missing systems in values before send request
export const addMissingSystems = ({ values }) => {
	const systems = ['active_tvonline', 'active_ott', 'active_obox'];
	const valuesEntries = Object.entries(values);

	// add value false to every missing systems
	const missingSystemsEntries = systems
		.filter((system) => !values[system])
		.map((system) => [system, false]);

	const data = Object.fromEntries([...valuesEntries, ...missingSystemsEntries]);

	return data;
};

// remove any special characters and leave only digits separated by comma
// if empty value return null
export const formatSNList = (data) => {
	// replace all non digit characters and multiple commas
	const formattedData = data.replace(/[^\d,]/gi, '').replace(/,+/g, ',');
	return formattedData.length ? formattedData : '';
};
