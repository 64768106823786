import i18n from 'i18next';

export const defaultFieldValues = {
	type: 'provision',
	free_vod: false,
	order: null,
	url: '',
	active: false,
	active_ott: false,
	active_obox: false,
	active_tvonline: true,
	slug: null,
	external_ids: [],
	visible_primary: false,
	visible_secondary: false,
	all_products: false
};

export const BASE_FIELDS = ({
	isEdit,
	checkedPermissions,
	currentValues,
	setProductTypeDefaultValue
}) => [
	{
		name: 'type',
		label: i18n.t('provisioning:fields.type'),
		type: 'select',
		requiredField: true,
		disabled: true
	},
	{
		name: 'external_ids',
		label: i18n.t('provisioning:fields.external_id'),
		type: 'multi-select',
		dropdownStyle: { display: 'none' },
		mode: 'tags'
	},
	{
		name: 'slug',
		label: i18n.t('provisioning:fields.slug'),
		type: 'text'
	},
	{
		name: 'title',
		label: i18n.t('provisioning:fields.title'),
		type: 'text',
		requiredField: true
	},
	{
		name: 'description',
		label: i18n.t('provisioning:fields.description'),
		type: 'textarea',
		requiredField: true
	},
	{
		name: 'short_description',
		label: i18n.t('provisioning:fields.short_description'),
		type: 'textarea',
		requiredField: true
	},
	{
		name: 'active',
		label: i18n.t('provisioning:fields.active'),
		type: 'checkbox',
		disabled: !checkedPermissions.canActivateProduct
	},
	{
		name: 'free_vod',
		label: i18n.t('provisioning:fields.free_vod'),
		type: 'checkbox',
		disabled: !checkedPermissions.canActivate
	},
	{
		name: 'all_products',
		label: i18n.t('provisioning:fields.all_products'),
		type: 'checkbox',
		disabled: !checkedPermissions.canActivate
	},
	{
		name: 'product_type',
		label: i18n.t('provisioning:fields.product_type'),
		type: 'multi-select',
		mode: null,
		defaultValue: setProductTypeDefaultValue(),
		disabled: !currentValues.all_products
	},
	{
		name: 'created_at',
		label: i18n.t('provisioning:fields.created_at'),
		type: 'text',
		disabled: true,
		isVisible: !isEdit
	},
	{
		name: 'updated_at',
		label: i18n.t('provisioning:fields.updated_at'),
		type: 'text',
		disabled: true,
		isVisible: !isEdit
	}
];

export const VISIBLE_FIELDS = [
	{
		name: 'visible_primary',
		label: i18n.t('provisioning:fields.visible_primary'),
		type: 'checkbox'
	},
	{
		name: 'visible_secondary',
		label: i18n.t('provisioning:fields.visible_secondary'),
		type: 'checkbox'
	}
];
