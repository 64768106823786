import React, { useEffect, useRef, useState } from 'react';

// Import shaka-player
import shaka from 'shaka-player/dist/shaka-player.ui.js';

// Import helpers
import { fetchPlaylistData, getPlayerConfiguration } from './helpers';
import { checkIsBrowserSafari } from 'components/helpers';

const ShakaPlayer = ({ playlist, videoFormat }) => {
	const [playlistData, setPlaylistData] = useState(null);

	// Creating reference to store video component and video container
	const videoComponentRef = useRef(null);
	const videoContainerRef = useRef(null);
	const playerRef = useRef(null);

	const handleFetchPlaylistData = async () => {
		const data = await fetchPlaylistData(playlist);
		setPlaylistData(data);
	};

	const handleAddTextTrack = ({ language: lang, url }) => {
		playerRef.current.addTextTrack(url, lang, 'subtitle', 'text/vtt', '', lang);
	};

	const isSafari = checkIsBrowserSafari();

	useEffect(() => {
		handleFetchPlaylistData();
		// eslint-disable-next-line
	}, [playlist]);

	useEffect(() => {
		shaka.polyfill.installAll();

		if (shaka.Player.isBrowserSupported() && playlistData) {
			// Getting reference to video and video container on DOM
			const video = videoComponentRef.current;
			const videoContainer = videoContainerRef.current;

			const {
				playlistData: { drm, sources, subtitles },
				certificate
			} = playlistData;

			// Link to manifest
			const manifestUri = sources[videoFormat][0].src;

			//Initialize shaka player
			const player = (playerRef.current = new shaka.Player(video));

			// Setting up shaka player UI
			const ui = new shaka.ui.Overlay(player, videoContainer, video);

			ui.configure({ addBigPlayButton: true });
			ui.getControls();

			// player configure
			const playerConfig = getPlayerConfiguration(drm, certificate);
			player.configure(playerConfig);

			// Try to asynchronous manifest load
			player
				.load(manifestUri)
				.then(() => {
					// add subtitles
					subtitles.forEach(handleAddTextTrack);
				})
				.catch((error) => console.log(error));
		}

		// eslint-disable-next-line
	}, [videoComponentRef, videoContainerRef, playlistData]);

	const subtitlesData = playlistData?.playlistData?.subtitles;

	return (
		<div className="video-container" ref={videoContainerRef}>
			<video
				className="shaka-video"
				id="shaka-video"
				ref={videoComponentRef}
				autoPlay
			>
				{isSafari &&
					subtitlesData?.map(({ id, language, url }) => (
						<track
							key={id}
							label="Subtitles"
							kind="subtitles"
							srcLang={language}
							src={url}
							default=""
						/>
					))}
			</video>
		</div>
	);
};

export default ShakaPlayer;
